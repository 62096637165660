<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="mt-4" novalidate @submit.prevent="handleSubmit(onSubmit)">
      <ValidationProvider name="Login" rules="required" v-slot="{ errors }">
        <div class="box">
          <label for="emailInput">Login:</label>
          <input
            ref="login"
            :class="
              'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')
            "
            :style="
              isIOS
                ? 'margin: 0px; padding-left: 6px; padding-bottom: 0px; padding-top: 0px; padding-rigth: 0px; height: 35px'
                : ''
            "
            @click="selecionarTexto"
            aria-describedby="emailHelp"
            v-model="auth.login"
            v-uppercase
            placeholder="Digite seu login..."
            required
          />
          <div class="invalid-feedback">
            <span>Login inválido</span>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider
        vid="password"
        name="Password"
        rules="required"
        v-slot="{ errors }"
      >
        <div class="box">
          <label for="passwordInput">Senha:</label>
          <input
            ref="passoword"
            type="password"
            :class="
              'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')
            "
            id="passwordInput"
            :style="
              isIOS
                ? 'margin: 0px; padding-left: 6px; padding-bottom: 0px; padding-top: 0px; padding-rigth: 0px; height: 35px'
                : ''
            "
            @click="selecionarPassord"
            v-model="auth.password"
            placeholder="Digite sua senha..."
            required
          />
          <div class="invalid-feedback">
            <span>Senha inválida</span>
          </div>
          <div
            v-if="capsLock"
            style="color: #e56773; margin: 0px; font-size: 0.6rem"
          >
            <span>Capslock ativado</span>
          </div>
        </div>
        <router-link to="/auth/password-reset" class="float-left">
          Esqueceu a senha?
        </router-link>
      </ValidationProvider>
      <div class="d-inline-block w-100">
        <button
          type="submit"
          :disabled="logging"
          class="btn btn-primary float-right"
          style="width: 80px"
        >
          <b-spinner v-if="logging" small label="Spinning"></b-spinner>
          <span v-else>Acessar</span>
        </button>
      </div>
      <div class="sign-info">
        <span class="dark-color d-inline-block line-height-2">
          Primeiro acesso?
          <router-link
            to="/auth/sign-up/page-form1"
            class="iq-waves-effect pr-4"
          >
            <b-button variant="primary"> Cadastre-se</b-button>
          </router-link>
        </span>
      </div>
    </form>

    <!-- Modal de Seleção de Perfil -->
    <b-modal
      v-model="showProfileSelectionModal"
      @hide="resetProfileSelection"
      title="Selecione o perfil"
      title-class="text-light"
      header-bg-variant="primary"
      no-close-on-esc
      no-close-on-backdrop
      cancel-title="Cancelar"
      cancel-variant="danger"
      class="custom-large-modal"
    >
      <template #default>
        <v-select
          v-model="selectedProfile"
          :options="
            availableProfiles.map((perfil, index) => ({
              index,
              label: perfil?.descPerfil
            }))
          "
          placeholder="Selecione um perfil..."
          size="lg"
        />
      </template>
      <template #modal-footer>
        <b-button variant="danger" @click="resetProfileSelection">
          Cancelar
        </b-button>
        <b-button variant="primary" @click="selectProfile"> Salvar </b-button>
      </template>
    </b-modal>
    <terms-of-use-modal
      v-if="termsOfUseIsVisible"
      :alreadyAccepted="false"
      @close="termsOfUseIsVisible = false"
      @confirm="continueLogin"
    />
    <RenewPassword
      v-if="showRenewPassword"
      @closeModal="closeShowRenewPassword"
      :idUsuario="idUser"
    />
  </ValidationObserver>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import TermsOfUseModal from '../../../components/TermsOfUseModal.vue'
import RenewPassword from '../../User/RenewPassword.vue'

export default {
  name: 'SignInForm',
  components: {
    TermsOfUseModal,
    RenewPassword
  },
  mounted() {
    this.$refs.login.focus()
    if (this.isIOSOrMac()) {
      this.isIOS = true
    }
    window.addEventListener('keydown', this.checkCapsLock)
    window.addEventListener('keyup', this.checkCapsLock)
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.checkCapsLock)
    window.removeEventListener('keyup', this.checkCapsLock)
  },
  data: () => ({
    auth: {
      login: '',
      password: ''
    },
    capsLock: false,
    isIOS: false,
    logging: false,
    userData: '',
    termsOfUseIsVisible: false,
    showRenewPassword: false,
    idUser: '',
    availableProfiles: [],
    selectedProfile: null,
    showProfileSelectionModal: false
  }),
  methods: {
    ...mapActions({
      getInfosTourOfUser: 'User/getInfosTourOfUser',
      login: 'Setting/loginUserAction',
      checkedIsExistCertifyAction: 'Forms/checkedIsExistCertifyAction',
      confirmLogin: 'Setting/confirmLoginUserAction',
      editUser: 'User/editUserAction',
      changeAssignModalVisibility:
        'DigitalAssign/changeAssignModalVisibilityAction',
      changeDigitalAssignSwitchState: 'DigitalAssign/changeSwitchStateAction'
    }),
    selecionarTexto() {
      this.$refs.login.select()
    },
    selecionarPassord() {
      this.$refs.passoword.select()
    },
    checkCapsLock(event) {
      const capsLockOn =
        event.getModifierState && event.getModifierState('CapsLock')
      this.capsLock = capsLockOn
    },
    closeShowRenewPassword() {
      this.showRenewPassword = false
    },
    isIOSOrMac() {
      const userAgent = window.navigator.userAgent.toLowerCase()
      return /iphone|ipad|ipod|macintosh/i.test(userAgent)
    },
    checkedStatusAssing() {
      const user = this.userData
      const resp = this.checkedIsExistCertifyAction(user.cpf)
      if (resp) {
        this.changeAssignModalVisibility(false)
      }
    },
    async onSubmit() {
      const { login, password } = this.auth
      this.logging = true
      try {
        const userData = await this.login({ login, password })
        this.userData = userData
        if (
          this.userData.perfil_usuario &&
          this.userData.perfil_usuario.length > 1
        ) {
          this.availableProfiles = this.userData.perfil_usuario
          this.showProfileSelectionModal = true
          this.logging = false
          return
        } else if (
          this.userData.perfil_usuario &&
          this.userData.perfil_usuario.length === 1
        ) {
          // Caso com um único perfil
          const profile = this.userData
          this.idUser = profile?.idUsuario || ''
          this.selectedProfile = { index: 0, label: profile.descPerfil } // Atualizar o selectedProfile
          this.userData.selectedProfileIndex = 0
          await this.proceedWithLogin({ index: 0 }) // Passar o objeto com index igual a 0
        }

        if (this.userData.aceitoTermo === 0) {
          this.confirmLogin(this.userData)
          if (this.userData.alterarSenha === 0) {
            this.showRenewPassword = true
            this.idUser = this.userData?.id
            this.logging = false
          } else {
            const response = await this.getInfosTourOfUser(this.userData?.id)

            if (response.primeiroAcesso === 0) {
              this.checkedStatusAssing()
              this.$router.push({ name: 'register.schedules' })
            } else {
              if (
                this.userData.perfil_usuario[0] &&
                this.userData.perfil_usuario[0].descPerfil !== 'RECEPCIONISTA'
              ) {
                this.checkedStatusAssing()
              } else {
                this.checkedStatusAssing()
                this.$router.push({ name: 'pages.calendar' })
              }
            }
          }
        } else {
          this.logging = false
          this.termsOfUseIsVisible = true
          this.$toast.info('Aceite os termos de uso para continuar!')
        }
      } catch (error) {
        this.logging = false
        if (error.message) {
          this.$toast.error(`${error.message} `)
        }
        if (error && error.result === false) {
          this.$toast.error(
            error.message || 'Usuário ou senha informada é inválida!'
          )
        } else {
          // this.$toast.error('Ocorreu um erro ao tentar realizar login.')
        }
      }
    },
    async selectProfile() {
      if (!this.selectedProfile) {
        this.$toast.warning(
          'Por favor, selecione um perfil antes de prosseguir.'
        )
        return
      }

      try {
        await this.proceedWithLogin()
        this.showProfileSelectionModal = false
      } catch (error) {
        this.$toast.error(
          'Erro ao tentar realizar o login com o perfil selecionado.'
        )
      }
    },
    async proceedWithLogin() {
      // Lógica para continuar o fluxo de login com o perfil selecionado
      this.confirmLogin({
        ...this.userData,
        selectedProfileIndex: this.selectedProfile.index
      })
      this.$toast.success('Login realizado com sucesso!')
      this.$router.push({ name: 'pages.calendar' })
    },

    resetProfileSelection() {
      this.selectedProfile = null // Resetar seleção
      this.availableProfiles = [] // Limpar perfis disponíveis
      this.showProfileSelectionModal = false // Fechar o modal
    },

    async continueLogin() {
      try {
        this.userData.aceitoTermo = 0
        await this.editUser(this.userData)
        this.confirmLogin(this.userData)
        this.$toast.success('Login realizado com sucesso!')
        this.$router.push({ name: 'pages.calendar' })
      } catch (error) {
        this.$toast.error(error.response.data.message)
      }
    }
  },
  computed: {
    ...mapState([]),
    perfil() {
      return this.selectedProfile
    },
    ...mapGetters({ digitalAssignSwitchState: 'DigitalAssign/switchState' })
  }
}
</script>

<style scoped>
.box {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.custom-large-modal .modal-dialog {
  max-width: 900px; /* Define o tamanho máximo do modal */
  width: 100%; /* Ocupa toda a largura possível */
}
</style>
