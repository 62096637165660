<template>
  <div>
    <ValidationObserver ref="addSchedule" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(saveSchedule)">
        <b-row>
          <b-form-group
            class="col-md-6"
            label-for="profissional"
            label="Profissional:"
          >
            <ValidationProvider
              name="profissional"
              rules="required"
              v-slot="{ errors }"
            >
              <v-select
                data-v-step="1"
                :options="professionals"
                v-model="selectedProfessional"
                :clearable="false"
                v-if="userIsSecretary"
                label="nmUsuario"
                :class="errors.length > 0 ? ' is-invalid' : ''"
              />
              <b-form-input
                data-v-step="1"
                :value="schedule.professional.name"
                v-else
                readonly
                type="text"
                label="primeiraConsulta"
              >
              </b-form-input>
              <div class="invalid-feedback">
                <span>Informe um profissional</span>
              </div>
            </ValidationProvider>
          </b-form-group>

          <b-form-group class="col-md-6" label-for="clinica" label="Clinica:">
            <ValidationProvider
              name="clinica"
              rules="required"
              v-slot="{ errors }"
            >
              <v-select
                data-v-step="2"
                :disabled="userIsSecretary"
                v-model="schedule.clinic"
                @change="setProfessional"
                :options="clinics"
                :clearable="false"
                label="nmClinica"
                :class="errors.length > 0 ? ' is-invalid' : ''"
              ></v-select>
              <div class="invalid-feedback">
                <span>Informe uma clinica</span>
              </div>
            </ValidationProvider>
          </b-form-group>

          <b-form-group
            class="col-md-3"
            label-for="primeiraConsulta"
            label="Primeira Consulta:"
          >
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-form-input
                v-model="schedule.firstConsult"
                type="time"
                name="primeiraConsulta"
                :class="errors.length > 0 ? ' is-invalid' : ''"
              >
              </b-form-input>
              <div class="invalid-feedback">
                <span>Informe o horário da primeira consulta</span>
              </div>
            </ValidationProvider>
          </b-form-group>

          <b-form-group
            class="col-md-3"
            label-for="ultimaConsulta"
            label="Ultima Consulta:"
          >
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-form-input
                v-model="schedule.lastConsult"
                type="time"
                :class="errors.length > 0 ? ' is-invalid' : ''"
              >
              </b-form-input>
              <div class="invalid-feedback">
                <span>Informe o horário da última consulta</span>
              </div>
            </ValidationProvider>
          </b-form-group>

          <b-form-group
            class="col-md-2"
            label-for="tempoConsulta"
            label="Tempo da consulta:"
          >
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-form-input
                v-model="schedule.consultTime"
                type="time"
                label="tempoConsulta"
                :class="errors.length > 0 ? ' is-invalid' : ''"
              >
              </b-form-input>
              <div class="invalid-feedback">
                <span>Informe o tempo da consulta</span>
              </div>
            </ValidationProvider>
          </b-form-group>

          <b-form-group
            class="col-md-2"
            label-for="inicioIntervalo"
            label="Inicio Intervalo:"
          >
            <ValidationProvider
              vid="inicioIntervalo"
              :rules="{
                required:
                  schedule.endInterval || schedule.endInterval.length > 0
              }"
              v-slot="{ errors }"
            >
              <b-form-input
                v-model="schedule.startInterval"
                type="time"
                label="inicioIntervalo"
                id="inicioIntervalo"
                :class="errors.length > 0 ? ' is-invalid' : ''"
              />
              <div v-if="errors.length > 0" class="invalid-feedback">
                <span>Necessário prencher o período</span>
              </div>
            </ValidationProvider>
          </b-form-group>

          <b-form-group
            class="col-md-2"
            label-for="fimIntervalo"
            label="Fim Intervalo:"
          >
            <ValidationProvider
              vid="fimIntervalo"
              :rules="{
                required:
                  schedule.startInterval || schedule.startInterval.length > 0
              }"
              v-slot="{ errors }"
            >
              <b-form-input
                v-model="schedule.endInterval"
                type="time"
                label="fimIntervalo"
                id="fimIntervalo"
                :class="errors.length > 0 ? ' is-invalid' : ''"
              />
              <div v-if="errors.length > 0" class="invalid-feedback">
                <span>Necessário prencher o período</span>
              </div>
            </ValidationProvider>
          </b-form-group>

          <b-form-group
            class="col-md-2 d-flex flex-column align-items-center text-center text-nowrap"
            label="Selecionar dias úteis"
          >
            <b-form-checkbox
              v-model="selectedBusinessDays"
              @change="toggleBusinessDays($event)"
              switch
            >
            </b-form-checkbox>
          </b-form-group>

          <b-form-group class="col-md-10">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-form-checkbox-group
                v-model="schedule.selectedDays"
                :options="dayOptions"
                text-field="description"
                value-field="id"
                :class="errors.length > 0 ? ' is-invalid' : ''"
              ></b-form-checkbox-group>
              <div class="invalid-feedback">
                <span>Selecione pelo menos um dia da semana</span>
              </div>
            </ValidationProvider>
          </b-form-group>

          <b-form-group class="col-md-12">
            <div class="buttons">
              <b-button data-v-step="3" variant="primary" type="submit"
                >Adicionar agenda</b-button
              >
            </div>
          </b-form-group>
        </b-row>
      </form>
    </ValidationObserver>
    <v-tour name="tourH" :steps="steps">
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
            id="step-tour2"
            v-if="tour.steps[tour.currentStep]"
            :key="tour.currentStep"
            :step="tour.steps[tour.currentStep]"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :skip="tour.skip"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
          >
            <template v-if="tour.currentStep === 0">
              <div id="header-step" slot="header">
                <p style="padding: 0px; margin: 0px">
                  Bem vindo ao Tour do sistema!
                </p>
              </div>
              <div id="content-step" slot="content">
                <span>Vamos começar nosso Tour pelo sistema!!</span>
                <div id="title-text">
                  Nesta pagina poderemos configurar os dias da semana, horários
                  e intervalo das consultas.
                </div>
                <div id="title-text">
                  Se você for uma secretaria escolha um medico para criar o
                  horario para ele. Se você for medico seu nome já estará
                  selecionando!
                </div>
              </div>
              <div slot="actions" id="footer-step">
                <span style="color: #2b2b2b"
                  >{{ tour.currentStep + 1 }} de {{ steps.length }}</span
                >
                <div id="butons-step">
                  <button @click="finallyTuor()" class="button-cancel">
                    Cancelar
                  </button>
                  <button @click="tour.skip" id="button-step-fin">
                    Pular Tour!
                  </button>
                  <button @click="tour.nextStep" id="button-step">
                    Próximo
                  </button>
                </div>
              </div>
            </template>
            <template v-if="tour.currentStep === 1">
              <div id="header-step" slot="header">
                <p style="padding: 0px; margin: 0px">
                  Bem vindo ao Tour do sistema!
                </p>
              </div>
              <div id="content-step" slot="content">
                <div id="title-text">
                  Agora vamos escolher qual clinica esses horarios seram
                  aplicados!
                </div>
              </div>
              <div slot="actions" id="footer-step">
                <span style="color: #2b2b2b"
                  >{{ tour.currentStep + 1 }} de {{ steps.length }}</span
                >
                <div id="butons-step">
                  <button @click="finallyTuor()" class="button-cancel">
                    Cancelar
                  </button>
                  <button @click="tour.skip" id="button-step-fin">
                    Pular Tour!
                  </button>
                  <button @click="tour.previousStep" id="button-step">
                    Voltar
                  </button>
                  <button @click="tour.nextStep" id="button-step">
                    Próximo
                  </button>
                </div>
              </div>
            </template>
            <template v-if="tour.currentStep === 2">
              <div id="header-step" slot="header">
                <p style="padding: 0px; margin: 0px">
                  Bem vindo ao Tour do sistema!
                </p>
              </div>
              <div id="content-step" slot="content">
                <div id="title-text">
                  Agora vamos adicionar esses horarios na agenda!
                </div>
                <div id="title-text">
                  OBS: Depois disso você será mandado para tela da agenda para
                  caddastrar consultas!
                </div>
              </div>
              <div slot="actions" id="footer-step">
                <span style="color: #2b2b2b"
                  >{{ tour.currentStep + 1 }} de {{ steps.length }}</span
                >
                <div id="butons-step">
                  <button @click="finallyTuor()" class="button-cancel">
                    Cancelar
                  </button>
                  <button @click="tour.skip" id="button-step-fin">
                    Pular Tour!
                  </button>
                  <button @click="tour.previousStep" id="button-step">
                    Voltar
                  </button>
                  <button @click="navigatForCalander()" id="button-step">
                    Próximo
                  </button>
                </div>
              </div>
            </template>
          </v-step>
        </transition>
      </template>
    </v-tour>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import dayOptions from '../../Utils/dayOptions'
import moment from 'moment'

export default {
  name: 'ScheduleForm',
  data() {
    return {
      dayOptions,
      selectedProfessional: '',
      professionals: [],
      schedule: {
        responsibleUser: '',
        professional: {
          name: '',
          id: 0
        },
        clinic: '',
        firstConsult: '',
        lastConsult: '',
        startInterval: '',
        endInterval: '',
        consultTime: '',
        selectedDays: []
      },
      clinics: [],
      steps: [
        {
          target: '[data-v-step="1"]',
          params: {
            placement: 'bottom'
          }
        },
        {
          target: '[data-v-step="2"]',
          params: {
            placement: 'bottom'
          }
        },
        {
          target: '[data-v-step="3"]',
          params: {
            placement: 'left'
          }
        }
      ]
    }
  },
  async mounted() {
    this.schedule.responsibleUser = this.user.id
    await this.getClinicsByUser()

    if (this.userIsSecretary) {
      await this.getProfessionalsByClinic()
      this.selectedProfessional = this.professionals[0]
    } else {
      this.setProfessional(this.user)
    }
    if (this.getIsPrimaryAcess.primeiroAcesso === 0) {
      this.$tours.tourH.start()
    }
  },
  watch: {
    selectedProfessional(newValue) {
      this.setProfessional(newValue)
    }
  },
  computed: {
    ...mapGetters({
      getIsPrimaryAcess: 'User/getIsPrimaryAcess',
      user: 'Setting/user'
    }),
    userIsSecretary() {
      return [2, 3].includes(
        this.user.perfil_usuario[this.user.selectedProfileIndex].idPerfil
      )
    },
    businessDays() {
      const businessDays = [...this.dayOptions].filter((day) => {
        return day.description.includes('feira')
      })

      const businessDaysIds = [...businessDays].map((day) => {
        return day.id
      })

      return businessDaysIds
    },
    selectedBusinessDays: {
      get() {
        const selectedBusinessDays = this.businessDays.every((element) => {
          return this.schedule.selectedDays.includes(element)
        })
        return selectedBusinessDays
      },

      set() {}
    }
  },
  methods: {
    ...mapActions({
      setInfosTourOfUser: 'User/setInfosTourOfUser',
      getUserClinicsAction: 'User/getUserClinicsAction',
      saveScheduleAction: 'Schedule/saveScheduleAction',
      getUsersByClinicAction: 'User/getUsersByClinicAction'
    }),
    finallyTuor() {
      const object = {
        idUsuario: this.user.id,
        dtCadastro: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        primeiroAcesso: 1,
        stepTour: 0
      }
      this.setInfosTourOfUser(object)
      this.$tours.tourH.stop()
    },

    navigatForCalander() {
      this.$router.push({ name: 'pages.calendar' })
    },

    openTour() {
      const scriptElement = document.getElementById('step-tour')
      if (scriptElement === null) {
        this.$tours.tourH.start()
      } else {
        this.$tours.tourH.stop()
      }
    },
    async getClinicsByUser() {
      const clinics = await this.getUserClinicsAction(this.user.id)

      this.clinics = clinics
      this.schedule.clinic = clinics[0]
    },

    async getProfessionalsByClinic() {
      // TO DO: verify system requirement for this function
      const {
        clinic: { idClinica }
      } = this.schedule

      const professionals = await this.getUsersByClinicAction(idClinica)

      const doctorsAndNurses = professionals.filter((item) => {
        return [1, 4].includes(item.perfil.id)
      })

      this.professionals = doctorsAndNurses
    },

    setProfessional(user) {
      const { nome, id, nmUsuario, idUsuario } = user

      if (nmUsuario && idUsuario) {
        this.schedule.professional = {
          name: nmUsuario,
          id: idUsuario
        }
      } else {
        this.schedule.professional = {
          name: nome,
          id
        }
      }

      this.$emit('selectedProfessionalId', this.schedule.professional.id)
    },

    async saveSchedule() {
      const payload = {
        ...this.schedule,
        consultTime: this.convertClockTimeToIntMinutes(
          this.schedule.consultTime
        )
      }

      try {
        await this.saveScheduleAction(payload)

        this.$emit('selectedProfessionalId', payload.professional.id)

        this.$toast.success('Agenda salva com sucesso')
      } catch (error) {
        this.$toast.error(error.response.data.message)
      }
    },

    toggleBusinessDays(event) {
      if (event) {
        this.addBusinessDaysOnSelectedDays()
      } else {
        this.removeBusinessDaysFromSelectedDays()
      }
    },

    addBusinessDaysOnSelectedDays() {
      this.businessDays.forEach((dayId) => {
        const index = this.schedule.selectedDays.findIndex(
          (element) => element === dayId
        )

        if (index === -1) {
          this.schedule.selectedDays.push(dayId)
        }
      })
    },

    removeBusinessDaysFromSelectedDays() {
      this.businessDays.forEach((dayId) => {
        const index = this.schedule.selectedDays.findIndex(
          (element) => element === dayId
        )

        if (index !== -1) {
          this.schedule.selectedDays.splice(index, 1)
        }
      })
    },

    convertClockTimeToIntMinutes(time) {
      const [hours, minutes] = time.split(':')

      const hoursInMinutes = parseInt(hours) * 60
      const totalMinutes = hoursInMinutes + parseInt(minutes)

      return totalMinutes
    }
  }
}
</script>

<style scoped lang="scss">
.buttons {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
}
.v-step[data-v-54f9a632] {
  max-width: none;
}

#step-tour {
  width: 440px;
  background-color: #089bab;
  border: 1px solid #089bab;
  color: #089bab;
  padding: 0px;
}

#step-tour2 {
  width: 440px;
  background-color: #089bab;
  border: 1px solid #089bab;
  color: #089bab;
  padding: 0px;
}

#header-step {
  background-color: #f7f7f7;
  width: 700px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-bottom: #089bab 1px solid;
  padding: 6px 10px;
}

#content-step {
  background-color: #f7f7f7;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  padding: 6px 10px;
}

#content-step span {
  color: #000;
  font-size: 14px;
  font-weight: 500;
}

#title-text {
  font-size: 12px;
  font-weight: normal;
  color: #2b2b2b;
  text-align: start;
  padding: 0px;
  margin: 0px;
}

#footer-step {
  background-color: #f7f7f7;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 6px 10px;
}

#butons-step {
  display: flex;
  gap: 10px;
  align-items: center;
}

#button-step-fin {
  background-color: transparent;
  border: none;
  color: #bcb9b9;
  font-weight: bold;
}
.button-cancel {
  font-size: 0.8rem;
  padding: 6px 12px;
  color: red;
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid red;
}

#button-step {
  border-radius: 10px;
  padding: 6px 10px;
  background-color: #089bab;
  color: #fff;
  font-weight: bold;
}

#step-image {
  width: 100%;
  height: 200px;
}
</style>
