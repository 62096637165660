<template>
  <b-container fluid id="calenderId">
    <b-row>
      <b-col lg="12">
        <b-overlay :show="loadingUser" spinner-variant="primary">
          <iq-card class="iq-card_scoped">
            <template v-slot:body>
              <div class="totalizersHandler" data-v-step="1t">
                <div class="title-tour">
                  <h4 class="card-title">Agenda</h4>
                  <b-icon
                    @click="openTour()"
                    style="margin-bottom: 12px; cursor: pointer"
                    icon="patch-question-fill"
                    font-scale="1.5"
                    title="Click aqui para ativar o Tour dessa pagina!"
                  ></b-icon>
                </div>
                <div class="totalizersHandler">
                  <h5>Meu dia</h5>
                  <i
                    :class="showInfo ? 'fas fa-arrow-up' : 'fas fa-arrow-down'"
                    @click="showInfo = !showInfo"
                  ></i>
                </div>
              </div>
              <Totalizers
                ref="totalizers"
                v-if="showInfo"
                :doctor="doctor"
                :clinica="clinica"
                :date-to-view="dateToView"
              />
              <b-row>
                <b-form-group>
                  <ul class="sameLevel">
                    <li
                      @click="forwardDate()"
                      data-toggle="tooltip"
                      data-placement="top"
                      title=""
                      data-original-title="Previous"
                    >
                      <i class="ri-arrow-left-s-line"></i>
                    </li>
                    <li data-toggle="tooltip" data-placement="top">
                      <b-form-input
                        data-v-step="1"
                        :style="
                          isIOS
                            ? 'margin: 0px; padding-left: 6px; padding-bottom: 0px; padding-top: 0px; padding-rigth: 0px; height: 36px'
                            : ''
                        "
                        v-model="dateToView"
                        type="date"
                        @change="
                          getScheduleListByDoctor(
                            doctor.idUsuario,
                            clinica.idClinica
                          )
                        "
                      ></b-form-input>
                    </li>
                    <li
                      @click="nextDate()"
                      data-toggle="tooltip"
                      data-placement="top"
                      title=""
                      data-original-title="Next"
                    >
                      <i class="ri-arrow-right-s-line"></i>
                    </li>
                  </ul>
                </b-form-group>
                <b-form-group class="col-md-3">
                  <v-select
                    data-v-step="2"
                    v-if="isManager"
                    v-model="doctor"
                    :options="doctors"
                    label="nmUsuario"
                    :clearable="false"
                    @input="
                      getScheduleListByDoctor(
                        doctor.idUsuario,
                        clinica.idClinica
                      )
                    "
                  ></v-select>
                  <b-form-input
                    data-v-step="2"
                    v-else
                    readonly
                    :value="doctor.nmUsuario"
                    type="text"
                    label="nmUsuario"
                    :style="
                      isIOS
                        ? 'margin: 0px; padding-left: 6px; padding-bottom: 0px; padding-top: 0px; padding-rigth: 0px; height: 36px'
                        : ''
                    "
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group class="col-md-4">
                  <b-form-input
                    data-v-step="3"
                    v-if="isManager"
                    readonly
                    :value="clinica.nmClinica"
                    type="text"
                    label="nmClinica"
                    :style="
                      isIOS
                        ? 'margin: 0px; padding-left: 6px; padding-bottom: 0px; padding-top: 0px; padding-rigth: 0px; height: 36px'
                        : ''
                    "
                  ></b-form-input>
                  <v-select
                    data-v-step="3"
                    v-else
                    v-model="clinica"
                    :options="clinicas"
                    label="nmClinica"
                    :clearable="false"
                    @input="
                      getScheduleListByDoctor(
                        doctor.idUsuario,
                        clinica.idClinica
                      )
                    "
                  ></v-select>
                </b-form-group>
                <div class="buttons-container">
                  <b-button
                    data-v-step="4"
                    id="buttons-container"
                    variant="primary"
                    @click="handlerShowToSchedule"
                    >Encaixe</b-button
                  >
                  <b-button
                    data-v-step="5"
                    id="buttons-container"
                    variant="primary"
                    @click="handlerShowWaitingListModal"
                    >Lista de Espera</b-button
                  >
                  <div class="btn-group show">
                    <b-dropdown
                      variant="primary"
                      text="Localizar"
                      data-v-step="6"
                    >
                      <b-dropdown-item-button
                        @click="handlerShowFindScheduleModal"
                        >Horários Livres</b-dropdown-item-button
                      ><b-dropdown-divider></b-dropdown-divider>
                      <b-dropdown-item-button @click="handlerShowFindPatient"
                        >Paciente na agenda</b-dropdown-item-button
                      ><b-dropdown-divider></b-dropdown-divider>
                      <b-dropdown-item-button
                        v-if="isDoctor"
                        @click="handlerShowFindMedicalReportModal"
                        >Prontuário Paciente</b-dropdown-item-button
                      >
                    </b-dropdown>
                  </div>
                  <b-button
                    id="buttons-container"
                    variant="primary"
                    @click="openModalAgendInBlock()"
                    >Alteração em Bloco</b-button
                  >
                  <b-button
                    id="buttons-container"
                    variant="primary"
                    @click="getUser"
                    >Atualizar</b-button
                  >
                </div>
                <FindSchedule
                  v-if="showFindScheduleModal"
                  :doctor="doctor"
                  :clinica="clinica"
                  :clinicas="clinicas"
                  @updateCalendar="updateCalendar"
                  @modalClosed="modalClosed"
                  @handlerShowToScheduleFromSearch="
                    handlerShowToScheduleFromSearch
                  "
                />
                <FindPatient
                  v-if="showFindPatient"
                  :doctor="doctor"
                  :clinica="clinica"
                  :doctors="doctors"
                  :clinics="clinicas"
                  @updateCalendar="updateCalendar"
                  @modalClosed="modalClosed"
                />
                <FindMedicalReport
                  v-if="showFindMedicalReportModal"
                  :doctor="doctor"
                  :clinica="clinica"
                  @updateCalendar="updateCalendar"
                  @modalClosed="modalClosed"
                />
                <ToSchedule
                  v-if="showToSchedule"
                  :schedule="schedule"
                  :doctor="doctor.idUsuario"
                  :clinica="clinica.idClinica"
                  :date-to-view="dateToView"
                  @updateCalendar="updateCalendar"
                  @modalClosed="modalClosed"
                  @closeModal="showToSchedule = false"
                />

                <AwaitingListModal
                  v-if="showWaitingList"
                  @close="showWaitingList = false"
                  @updateCalender="getUser"
                />
              </b-row>
              <b-row>
                <div
                  :class="
                    showInfo ? 'table tableHeight2' : 'table tableHeight1'
                  "
                >
                  <div class="headerList">
                    <span class="list centralizeText">Hora</span>
                    <span class="list">Nome</span>
                    <span class="list">Observação</span>
                    <span class="list">Chegada</span>
                    <span class="list">Convênio</span>
                    <span class="list">Tipo atendimento</span>
                    <span class="list centralizeText">Situação</span>
                  </div>
                  <div
                    v-if="loading"
                    class="d-flex justify-content-center mt-3 mb-3"
                  >
                    <b-spinner variant="primary"></b-spinner>
                  </div>
                  <div
                    v-else
                    :class="'dataList onHover'"
                    :style="
                      schedule.idStatusAgenda
                        ? handlerColorByStatus(schedule.idStatusAgenda)
                        : null
                    "
                    v-for="schedule in scheduleList"
                    :key="schedule.id ? schedule.id : schedule.horaAgendamento"
                  >
                    <button
                      v-if="schedule.idStatusAgenda === 18"
                      id="button-hour"
                      v-b-tooltip.hover.focus
                      :title="tooltipText"
                    >
                      {{ schedule.hourFormated }}
                    </button>
                    <button
                      v-else
                      data-v-step="7"
                      id="button-hour"
                      :disabled="getDisabled(schedule.idStatusAgenda)"
                      @click="openModalWithHour(schedule)"
                    >
                      {{ schedule.hourFormated }}
                    </button>
                    <div
                      v-if="schedule.idStatusAgenda === 4"
                      class="showNextStatus"
                    >
                      <span
                        class="history"
                        style="
                          align-items: center;
                          background: #089bab;
                          color: #fff;
                          border-radius: 7px;
                          cursor: pointer;
                        "
                        @click="openPatientModal(schedule)"
                      >
                        Hist. paciente
                      </span>
                      <div
                        v-if="showProntuario()"
                        class="history"
                        style="
                          align-items: center;
                          background: #089bab;
                          color: #fff;
                          border-radius: 7px;
                          cursor: pointer;
                        "
                        @click="accessMedicalReport(schedule)"
                      >
                        <span>Acessar prontuário</span>
                      </div>
                    </div>

                    <div
                      class="showNextStatus"
                      v-if="schedule.idStatusAgenda !== 4"
                    >
                      <span
                        v-if="schedule.idStatusAgenda === 10"
                        class="history"
                        style="
                          align-items: center;
                          background: #089bab;
                          color: #fff;
                          border-radius: 7px;
                          cursor: pointer;
                        "
                        @click="accessMedicalReport(schedule)"
                      >
                        Acessar prontuário
                      </span>
                      <span
                        v-if="
                          schedule.idStatusAgenda === 11 &&
                          user.responsavelTriagem !== 0
                        "
                        >AGUARDANDO TRIAGEM</span
                      >
                      <span
                        class="statusBox"
                        v-if="
                          schedule.idStatusAgenda === 11 &&
                          user.responsavelTriagem !== 0
                        "
                        style="white-space: nowrap; margin-left: 10px"
                        @click="changeStatus(schedule, 2)"
                      >
                        <i class="fa fa-backward">
                          <span class="iconDescription">Presente</span>
                        </i>
                      </span>

                      <span v-if="schedule.idStatusAgenda === 12"
                        >TRIAGEM EM ANDAMENTO</span
                      >
                      <span
                        class="statusBox"
                        v-if="schedule.idStatusAgenda === 12"
                        style="white-space: nowrap; margin-left: 10px"
                        @click="changeStatus(schedule, 11)"
                      >
                        <i class="fa fa-backward">
                          <span class="iconDescription"
                            >AGUARDANDO TRIAGEM</span
                          >
                        </i>
                      </span>
                      <span
                        v-for="nextStatus in getNextStatusList(schedule)"
                        :key="nextStatus"
                        class="statusBox"
                        @click="
                          nextStatus === 9 &&
                          user.responsavelTriagem === 1 &&
                          schedule.idStatusAgenda !== 13
                            ? showIgnoreTriageModal(schedule)
                            : changeStatus(schedule, nextStatus)
                        "
                      >
                        <i :class="handlerStatusIcon(nextStatus)">
                          <span class="iconDescription"
                            >{{ showNextStatusDescription(nextStatus) }}
                          </span>
                        </i>
                      </span>

                      <span
                        class="statusBox"
                        v-if="
                          schedule.idTipoAtendimento === 2 &&
                          (schedule.idStatusAgenda === 2 ||
                            schedule.idStatusAgenda === 8)
                        "
                        style="white-space: nowrap"
                        @click="sendVideoEmail(schedule)"
                      >
                        <i class="fas arroy-left">
                          <span class="iconDescription">Reenviar Link</span>
                        </i>
                      </span>

                      <span
                        class="statusBox"
                        v-if="
                          schedule.idTipoAtendimento === 2 &&
                          (schedule.idStatusAgenda === 2 ||
                            schedule.idStatusAgenda === 8)
                        "
                        style="white-space: nowrap"
                        v-clipboard:copy="
                          defaultLink + schedule.identificacaoTeleconsulta
                        "
                        v-clipboard:success="onLinkCopy"
                        v-clipboard:error="onLinkCopyError"
                      >
                        <i class="fas fa-video">
                          <span class="iconDescription">Copiar Link</span>
                        </i>
                      </span>

                      <span
                        class="statusBox"
                        v-if="schedule.idStatusAgenda === 1"
                        style="white-space: nowrap"
                        @click="sendWhatsappConfirmationMessage(schedule)"
                      >
                        <i class="fab fa-whatsapp">
                          <span class="iconDescription">Whatsapp</span>
                        </i>
                      </span>
                      <span
                        class="statusBox"
                        v-if="schedule.idStatusAgenda === 1"
                        style="white-space: nowrap"
                        @click="sendLinkConfirm(schedule.id)"
                      >
                        <i class="fas fa-link">
                          <span class="iconDescription"
                            >Link de confirmação</span
                          >
                        </i>
                        <b-spinner
                          v-show="loadingLinkConf === true"
                          small
                        ></b-spinner>
                      </span>

                      <span
                        class="statusBox"
                        v-if="
                          schedule.idStatusAgenda === 1 &&
                          doctor.agendamentoRecorrente === 0
                        "
                        style="white-space: nowrap"
                        @click="getModalRecorrencia(schedule, doctor.idUsuario)"
                      >
                        <i class="fas fa-link">
                          <span class="iconDescription"
                            >Adicionar Recorrência</span
                          >
                        </i>
                      </span>
                      <span
                        class="history"
                        v-if="
                          schedule.idStatusAgenda !== 0 &&
                          schedule.idStatusAgenda !== 6 &&
                          schedule.idStatusAgenda !== 18 &&
                          schedule.idStatusAgenda !== 19
                        "
                        @click="openPatientModal(schedule)"
                        style="cursor: pointer"
                      >
                        Hist. paciente
                      </span>
                      <span>
                        <b-icon
                          v-if="schedule.idTipoAtendimento === 2"
                          icon="camera-video-fill"
                          font-scale="2"
                          variant="primary"
                          style="padding-top: 3px"
                      /></span>
                    </div>
                    <span class="scheduleDetails"
                      >{{ schedule.pacienteNome }}
                      <b-icon
                        v-if="schedule.idTipoAtendimento === 2"
                        icon="camera-video-fill"
                        font-scale="2"
                        variant="primary"
                        style="padding-top: 3px"
                    /></span>
                    <span class="scheduleDetails">{{
                      schedule.observação
                    }}</span>
                    <span class="scheduleDetails">{{
                      schedule.dtChegada
                        ? getArriveHour(schedule.dtChegada)
                        : null
                    }}</span>
                    <span class="scheduleDetails">{{
                      schedule.convenioDesc
                    }}</span>
                    <span class="scheduleDetails">{{
                      schedule.descTipoAgendamento
                    }}</span>
                    <span class="scheduleDetails"></span>
                    <span class="scheduleDetails">
                      <span
                        v-if="
                          schedule.viaOnline === 'S' &&
                          schedule.idStatusAgenda !== 18
                        "
                        class="iconDescription"
                      >
                        <i class="fa fa-globe"></i>
                      </span>
                      <i :class="handlerStatusIcon(schedule.idStatusAgenda)">
                      </i
                      ><span class="iconDescription"
                        >{{
                          showNextStatusDescription(schedule.idStatusAgenda)
                        }}
                      </span>
                    </span>

                    <span class="scheduleDetails">
                      <span v-if="verifySignature(schedule)"> </span>
                      <span
                        v-else
                        id="tooltip"
                        target="icon"
                        v-b-tooltip.hover.focus.v-info
                        :title="getTestSignature(schedule)"
                      >
                        <img
                          src="../../assets/images/digital-signature.png"
                          alt=""
                          class="imgSignature"
                        />
                      </span>
                    </span>
                    <!-- <b-icon
                      v-if="schedule.idTipoAtendimento === 2"
                      icon="camera-video-fill"
                      font-scale="2"
                      variant="primary"
                      style="padding-top: 3px"
                    /> -->
                  </div>
                </div>
              </b-row>
            </template>
            <v-tour name="tourFinally" :steps="steps2">
              <template slot-scope="tour">
                <transition name="fade">
                  <v-step
                    id="step-tour4"
                    v-if="tour.steps[tour.currentStep]"
                    :key="tour.currentStep"
                    :step="tour.steps[tour.currentStep]"
                    :previous-step="tour.previousStep"
                    :next-step="tour.nextStep"
                    :stop="tour.stop"
                    :skip="tour.skip"
                    :cancel="tour.cancel"
                    :is-first="tour.isFirst"
                    :is-last="tour.isLast"
                    :labels="tour.labels"
                  >
                    <template v-if="tour.currentStep === 0">
                      <div id="header-step" slot="header">
                        <p style="padding: 0px; margin: 0px">
                          Finalização do Tour!
                        </p>
                      </div>
                      <div id="content-step" slot="content">
                        <span>Aqui você pode ver seu remdimento!</span>

                        <div id="title-text">
                          Acompanhe o rendimento do seu dia de acordo com as
                          movimentações da agenda do dia.
                        </div>
                      </div>
                      <div slot="actions" id="footer-step">
                        <div id="butons-step">
                          <button @click="tour.skip" id="button-step-fin">
                            Pular Tour!
                          </button>
                          <button @click="finallyTuor()" id="button-step">
                            Concluir
                          </button>
                        </div>
                      </div>
                    </template>
                  </v-step>
                </transition>
              </template>
            </v-tour>
            <v-tour name="myTour" :steps="steps">
              <template slot-scope="tour">
                <transition name="fade">
                  <v-step
                    id="step-tour"
                    v-if="tour.steps[tour.currentStep]"
                    :key="tour.currentStep"
                    :step="tour.steps[tour.currentStep]"
                    :previous-step="tour.previousStep"
                    :next-step="tour.nextStep"
                    :stop="tour.stop"
                    :skip="tour.skip"
                    :is-first="tour.isFirst"
                    :is-last="tour.isLast"
                    :labels="tour.labels"
                  >
                    <template v-if="tour.currentStep === 0">
                      <div id="header-step" slot="header">
                        <p style="padding: 0px; margin: 0px">
                          Bem vindo ao Tour do sistema!
                        </p>
                      </div>
                      <div id="content-step" slot="content">
                        <div id="title-text">
                          Navegue entre os dias da agenda para visualize os
                          agendamentos e horários disponíveis.
                        </div>
                      </div>
                      <div slot="actions" id="footer-step">
                        <span style="color: #2b2b2b"
                          >{{ tour.currentStep + 1 }} de
                          {{ steps.length }}</span
                        >
                        <div id="butons-step">
                          <button @click="finallyTuor()" class="button-cancel">
                            Cancelar
                          </button>
                          <button @click="tour.skip" id="button-step-fin">
                            Pular Tour!
                          </button>
                          <button @click="tour.nextStep" id="button-step">
                            Próximo
                          </button>
                        </div>
                      </div>
                    </template>
                    <template v-if="tour.currentStep === 1">
                      <div id="header-step" slot="header">
                        <p style="padding: 0px; margin: 0px">
                          Bem vindo ao Tour do sistema!
                        </p>
                      </div>
                      <div id="content-step" slot="content">
                        <div id="title-text">
                          Selecione qual profissional em que deseja visualizar e
                          agendar paciente. No caso de profissional de saúde,
                          irá visualizar apenas a sua agenda.
                        </div>
                      </div>
                      <div slot="actions" id="footer-step">
                        <span style="color: #2b2b2b"
                          >{{ tour.currentStep + 1 }} de
                          {{ steps.length }}</span
                        >
                        <div id="butons-step">
                          <button @click="finallyTuor()" class="button-cancel">
                            Cancelar
                          </button>
                          <button @click="tour.skip" id="button-step-fin">
                            Pular Tour!
                          </button>
                          <button @click="tour.previousStep" id="button-step">
                            Voltar
                          </button>
                          <button @click="tour.nextStep" id="button-step">
                            Próximo
                          </button>
                        </div>
                      </div>
                    </template>
                    <template v-if="tour.currentStep === 2">
                      <div id="header-step" slot="header">
                        <p style="padding: 0px; margin: 0px">
                          Bem vindo ao Tour do sistema!
                        </p>
                      </div>
                      <div id="content-step" slot="content">
                        <div id="title-text">
                          De acordo com seus acessos, navegue em suas clinicas
                          vinculadas no seu usuário.
                        </div>
                      </div>
                      <div slot="actions" id="footer-step">
                        <span style="color: #2b2b2b"
                          >{{ tour.currentStep + 1 }} de
                          {{ steps.length }}</span
                        >
                        <div id="butons-step">
                          <button @click="finallyTuor()" class="button-cancel">
                            Cancelar
                          </button>
                          <button @click="tour.skip" id="button-step-fin">
                            Pular Tour!
                          </button>
                          <button @click="tour.previousStep" id="button-step">
                            Voltar
                          </button>
                          <button @click="tour.nextStep" id="button-step">
                            Próximo
                          </button>
                        </div>
                      </div>
                    </template>
                    <template v-if="tour.currentStep === 3">
                      <div id="header-step" slot="header">
                        <p style="padding: 0px; margin: 0px">
                          Bem vindo ao Tour do sistema!
                        </p>
                      </div>
                      <div id="content-step" slot="content">
                        <div id="title-text">
                          Não há mais horarios livres na agenda, ou deseja
                          encaixar um paciente hora dos horários de atendimento
                          do profissional?
                        </div>
                        <a
                          href="https://doctorpephelp.zendesk.com/hc/pt-br/articles/12253741142811-Como-gerar-um-encaixe-na-agenda"
                          target="_blank"
                          rel="noopener noreferrer"
                          >Saiba mais!</a
                        >
                      </div>

                      <div slot="actions" id="footer-step">
                        <span style="color: #2b2b2b"
                          >{{ tour.currentStep + 1 }} de
                          {{ steps.length }}</span
                        >
                        <div id="butons-step">
                          <button @click="finallyTuor()" class="button-cancel">
                            Cancelar
                          </button>
                          <button @click="tour.skip" id="button-step-fin">
                            Pular Tour!
                          </button>
                          <button @click="tour.previousStep" id="button-step">
                            Voltar
                          </button>
                          <button @click="tour.nextStep" id="button-step">
                            Próximo
                          </button>
                        </div>
                      </div>
                    </template>
                    <template v-if="tour.currentStep === 4">
                      <div id="header-step" slot="header">
                        <p style="padding: 0px; margin: 0px">
                          Bem vindo ao Tour do sistema!
                        </p>
                      </div>
                      <div id="content-step" slot="content">
                        <div id="title-text">
                          Cadastre aqui os pacientes em uma lista de espera,
                          para caso tenha disponibilidade possa agendar
                          futuramente.
                        </div>
                        <a
                          href="https://doctorpephelp.zendesk.com/hc/pt-br/articles/12838014269083-Como-funciona-a-lista-de-espera"
                          target="_blank"
                          rel="noopener noreferrer"
                          >Saiba mais!</a
                        >
                      </div>
                      <div slot="actions" id="footer-step">
                        <span style="color: #2b2b2b"
                          >{{ tour.currentStep + 1 }} de
                          {{ steps.length }}</span
                        >
                        <div id="butons-step">
                          <button @click="finallyTuor()" class="button-cancel">
                            Cancelar
                          </button>
                          <button @click="tour.skip" id="button-step-fin">
                            Pular Tour!
                          </button>
                          <button @click="tour.previousStep" id="button-step">
                            Voltar
                          </button>
                          <button @click="tour.nextStep" id="button-step">
                            Próximo
                          </button>
                        </div>
                      </div>
                    </template>
                    <template v-if="tour.currentStep === 5">
                      <div id="header-step" slot="header">
                        <p style="padding: 0px; margin: 0px">
                          Bem vindo ao Tour do sistema!
                        </p>
                      </div>
                      <div id="content-step" slot="content">
                        <div id="title-text">
                          Neste local pode-se, pesquisar horários livres em um
                          período maior, pesquisa pacientes agendados ou
                          histórico de atendimento, e localizar prontuário (
                          Apenas para profissionais de saúde)
                        </div>
                        <a
                          href="https://doctorpephelp.zendesk.com/hc/pt-br/articles/12838014269083-Como-funciona-a-lista-de-espera"
                          target="_blank"
                          rel="noopener noreferrer"
                          >Como localizar horários livres ?</a
                        >
                        <a
                          href="https://doctorpephelp.zendesk.com/hc/pt-br/articles/12838014269083-Como-funciona-a-lista-de-espera"
                          target="_blank"
                          rel="noopener noreferrer"
                          >Como localizar pacientes na agenda ?</a
                        >
                        <a
                          href="https://doctorpephelp.zendesk.com/hc/pt-br/articles/12253900324123-Como-funciona-o-localizar-prontu%C3%A1rio"
                          target="_blank"
                          rel="noopener noreferrer"
                          >Como localizar prontuario na agenda ?</a
                        >
                      </div>

                      <div slot="actions" id="footer-step">
                        <span style="color: #2b2b2b"
                          >{{ tour.currentStep + 1 }} de
                          {{ steps.length }}</span
                        >
                        <div id="butons-step">
                          <button @click="finallyTuor()" class="button-cancel">
                            Cancelar
                          </button>
                          <button @click="tour.skip" id="button-step-fin">
                            Pular Tour!
                          </button>
                          <button @click="tour.previousStep" id="button-step">
                            Voltar
                          </button>
                          <button @click="tour.nextStep" id="button-step">
                            Próximo
                          </button>
                        </div>
                      </div>
                    </template>
                    <template v-if="tour.currentStep === 6">
                      <div id="header-step" slot="header">
                        <p style="padding: 0px; margin: 0px">
                          Bem vindo ao Tour do sistema!
                        </p>
                      </div>
                      <div id="content-step" slot="content">
                        <div id="title-text">
                          Selecione o horário livre desejado para agendar um
                          paciente. Ou selecione o horário pra editar o
                          agendamento caso o horário já esteja preenchido.
                        </div>
                      </div>
                      <div slot="actions" id="footer-step">
                        <span style="color: #2b2b2b"
                          >{{ tour.currentStep + 1 }} de
                          {{ steps.length }}</span
                        >
                        <div id="butons-step">
                          <button @click="finallyTuor()" class="button-cancel">
                            Cancelar
                          </button>
                          <button @click="tour.skip" id="button-step-fin">
                            Pular Tour!
                          </button>
                          <button @click="tour.previousStep" id="button-step">
                            Voltar
                          </button>
                          <button
                            @click="openModalAndFinshTour()"
                            id="button-step"
                          >
                            Próximo
                          </button>
                        </div>
                      </div>
                    </template>
                  </v-step>
                </transition>
              </template>
            </v-tour>
          </iq-card>
        </b-overlay>
      </b-col>
    </b-row>
    <ModalRecorrencia
      v-if="modalRec"
      :dataReconrrecia="this.dataReconrreciaSend"
      @closeModal="closeModalRecorrencia()"
    />
    <ModalAgendaBlock
      v-if="openModalBlock"
      @closeModal="closeModalAgendInBlock()"
    />
    <ModalCanceledRecorrencia
      v-if="modalCanceled"
      @cancelModal="canceledModal()"
      :idAgendamento="idAgendamentoCancel"
      :closeModal="closeCancledModal()"
    />
    <b-modal
      id="ignoreTriageModal"
      title="Pular triagem"
      title-class="text-light"
      header-bg-variant="primary"
      cancel-title="Cancelar"
      cancel-variant="danger"
      ok-title="Continuar"
      @ok="changeStatus(scheduleToIgnoreTriage, 9)"
    >
      <h3 class="warning-text">Atenção!</h3>

      <h4 class="warning-text">A triagem ainda não foi realizada.</h4>
      <br />
      <h4 class="warning-text">Caso deseje aguardar a triagem, clique em</h4>

      <h4 class="warning-text">
        "Aguardar triagem" no respectivo agendamento.
      </h4>
      <br />
      <h4 class="warning-text">
        Caso deseje pular a triagem, clique em Continuar.
      </h4>
    </b-modal>
    <b-modal
      id="patient-history-modal"
      title="Histórico do Paciente"
      title-class="text-light"
      header-bg-variant="primary"
      size="lg"
      v-model="showPatientHistoryModal"
      @hide="resetPatientHistoryModal"
      hide-footer
    >
      <div v-if="patientInfo">
        <h4>{{ patientInfo.nome }}</h4>

        <b-row class="mt-4">
          <b-form-group
            class="col-md-3"
            label="Ultima consulta:"
            label-for="ultimaConsulta"
          >
            <b-form-input
              v-if="isIOS"
              tabindex="-1"
              readonly
              v-model="patientInfo.dataUltimaConsulta"
              type="date"
              id="lastAppointment"
              class="input-custom input-last-appointment"
            ></b-form-input>
            <b-form-input
              v-else
              tabindex="-1"
              readonly
              v-model="patientInfo.dataUltimaConsulta"
              type="date"
              id="lastAppointment"
              class="input-custom input-last-appointment"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            class="col-md-3"
            label="Dias Ultima consulta:"
            label-for="ultimaConsulta"
          >
            <b-form-input
              v-if="isIOS"
              tabindex="-1"
              readonly
              :value="
                daysSinceLastAppointment
                  ? daysSinceLastAppointment.toString()
                  : '0'
              "
              id="sinceLastAppointment"
              class="input-custom input-since-last-appointment"
            ></b-form-input>
            <b-form-input
              v-else
              tabindex="-1"
              readonly
              :value="
                daysSinceLastAppointment
                  ? daysSinceLastAppointment.toString()
                  : '0'
              "
              id="sinceLastAppointment"
              class="input-custom input-since-last-appointment"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            class="col-md-3"
            label="Cancelamentos:"
            label-for="cancelamentos"
          >
            <b-form-input
              v-if="isIOS"
              :title="formatedCancels"
              tabindex="-1"
              readonly
              v-model="patientInfo.quantidadeCancelouPaciente"
              type="text"
              id="cancellations"
              class="input-custom input-cancellations"
            ></b-form-input>
            <b-form-input
              v-else
              :title="formatedCancels"
              tabindex="-1"
              readonly
              v-model="patientInfo.quantidadeCancelouPaciente"
              type="text"
              id="cancellations"
              class="input-custom input-cancellations"
            ></b-form-input>
          </b-form-group>

          <b-form-group class="col-md-3" label="Faltas:" label-for="faltas">
            <b-form-input
              v-if="isIOS"
              :title="formatedFaults"
              tabindex="-1"
              readonly
              v-model="patientInfo.quantidadeFaltaPaciente"
              type="text"
              id="faults"
              class="input-custom input-faults"
            ></b-form-input>
            <b-form-input
              v-else
              :title="formatedFaults"
              tabindex="-1"
              readonly
              v-model="patientInfo.quantidadeFaltaPaciente"
              type="text"
              id="faults"
              class="input-custom input-faults"
            ></b-form-input>
          </b-form-group>
        </b-row>

        <div v-if="patientInfo" class="table-container">
          <table
            v-if="
              patientInfo.listaHistoricoPaciente &&
              patientInfo.listaHistoricoPaciente.length > 0
            "
            class="table padded-table"
          >
            <thead class="table-head">
              <tr>
                <th>Data e Hora</th>
                <th>Tipo de Consulta</th>
                <th>Situação</th>
                <th>Convênio</th>
                <th>Valor da Consulta</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(historico, index) in patientInfo.listaHistoricoPaciente"
                :key="index"
              >
                <td>
                  {{ historico.dtHoraAgendamento || 'Data não informada' }}
                </td>
                <td>{{ historico.tipoConsulta || 'Não informado' }}</td>
                <td>{{ historico.situacao || 'Não informado' }}</td>
                <td>{{ historico.convenio || 'Não informado' }}</td>
                <td>{{ historico.valorConsulta || 'Não informado' }}</td>
              </tr>
            </tbody>
          </table>
          <p v-else class="no-history-message">
            Paciente ainda não possui histórico.
          </p>
        </div>
      </div>
    </b-modal>
  </b-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

import API from '../../services/index'
import moment from 'moment'
import iqCard from '../../components/xray/cards/iq-card.vue'
import ToSchedule from './ToScheduleModal'
import AwaitingListModal from './AwaitingListModal'
import FindSchedule from './FindScheduleModal.vue'
import FindPatient from './FindPatientModal.vue'
import FindMedicalReport from './FindMedicalReportModal.vue'
import Totalizers from './Totalizers.vue'
import { scheduleConfirmationMessage } from '../../Utils/whatsappMessages'
import {
  formatDate,
  // eslint-disable-next-line no-unused-vars
  formatLocalDateHour,
  formatDateHour,
  formatHour
} from '../../Utils/formatters'
import ModalRecorrencia from '../../components/Calendar/ModalRecorrencia.vue'
import ModalAgendaBlock from '../../components/Calendar/ModalAgendaBlock.vue'
import ModalCanceledRecorrencia from '../../components/Calendar/ModalCanceledRecorrencia.vue'

export default {
  components: {
    ModalRecorrencia,
    ModalAgendaBlock,
    ModalCanceledRecorrencia,
    iqCard,
    ToSchedule,
    FindSchedule,
    FindPatient,
    FindMedicalReport,
    Totalizers,
    AwaitingListModal
  },
  name: 'calendar',
  mounted() {
    this.getUser()
    this.getNotifications()
    if (this.isIOSOrMac()) {
      this.isIOS = true
    }
    if (this.getIsPrimaryAcess.primeiroAcesso === 0) {
      this.$tours.myTour.start()
    }
  },
  data() {
    return {
      tooltipText:
        'É necessário voltar o horário para livre antes de realizar agendamento.',
      steps: [
        {
          target: '[data-v-step="1"]',
          params: {
            placement: 'right'
          }
        },
        {
          target: '[data-v-step="2"]',
          params: {
            placement: 'right'
          }
        },
        {
          target: '[data-v-step="3"]',
          params: {
            placement: 'bottom'
          }
        },
        {
          target: '[data-v-step="4"]',
          params: {
            placement: 'bottom'
          }
        },
        {
          target: '[data-v-step="5"]',
          params: {
            placement: 'bottom'
          }
        },
        {
          target: '[data-v-step="6"]',
          params: {
            placement: 'bottom'
          }
        },
        {
          target: '[data-v-step="7"]',
          params: {
            placement: 'right'
          }
        }
      ],
      steps2: [
        {
          target: '[data-v-step="1t"]',
          params: {
            placement: 'top'
          }
        }
      ],
      openModalBlock: false,
      selectedPatientId: null,
      patientInfo: null,
      showPatientHistoryModal: false,
      showHistoryModal: false,
      isIOS: false,
      defaultLink: 'https://app.doctorpep.com.br/telemedicina/',
      modalOpen: false,
      authToken: {
        headers: { Authorization: `Bearer ${window.$cookies.get('token')}` }
      },
      showWaitingList: false,
      showInfo: true,
      freeSchedule: '',
      scheduleList: '',
      loadingLinkConf: false,
      dateToView: new Date()
        .toLocaleDateString()
        .split('/')
        .reverse()
        .join('-'),
      doctors: [],
      doctor: '',
      schedule: {},
      clinica: '',
      clinicas: [],
      showToSchedule: false,
      showFindPatient: false,
      showFindScheduleModal: false,
      showFindMedicalReportModal: false,
      loading: false,
      loadingPronturario: false,
      user: '',
      loadingUser: false,
      scheduleToIgnoreTriage: '',
      modalCanceled: false,
      modalRec: false,
      dataReconrreciaSend: {
        schedule: null,
        idUser: null
      },
      idAgendamentoCancel: null
    }
  },

  computed: {
    ...mapGetters({
      getIsPrimaryAcess: 'User/getIsPrimaryAcess',
      loggedUser: 'Setting/user',
      isManager: 'Setting/isManager',
      getValueIsPrimaryAcess: 'getValueIsPrimaryAcess'
    }),

    isDoctor() {
      return (
        this.loggedUser.perfil_usuario[this.loggedUser.selectedProfileIndex]
          .idPerfil === 1
      )
    },

    statusList() {
      return [
        {
          id: 0,
          description: 'Livre',
          icon: 'fas fa-unlock',
          nextStatus: [6, 18, 19]
        },
        {
          id: 1,
          description: 'Agendado',
          icon: 'fas fa-star',
          style: {
            background: '#ABEBC6'
          },
          nextStatus: [2, 3, 5, 7]
        },
        {
          id: 2,
          description: 'Presente',

          icon: 'fas fa-user-circle',
          style: {
            background: '#EDBB99'
          },
          nextStatus: [1, 5, this.doctor.utilizaProntuario === 'S' ? 9 : 4, 14]
        },
        {
          id: 3,
          description: 'Faltou',

          icon: 'fas fa-trash-alt',
          style: {
            background: '#D2B4DE'
          },
          nextStatus: [0, 1]
        },
        {
          id: 4,
          description: 'Atendido',

          icon: 'fas fa-check-double',
          style: {
            background: '#AED6F1'
          }
        },
        {
          id: 5,
          description: 'Cancelado',

          icon: 'fas fa-thumbs-down',
          style: {
            background: '#F5B7B1'
          },
          nextStatus: [0]
        },
        {
          id: 6,
          description: 'Bloquear horário',

          icon: 'fas fa-lock',
          style: {
            background: '#ABB2B9'
          },
          nextStatus: [0]
        },
        {
          id: 7,
          description: 'Confirmado',

          icon: 'fas fa-thumbs-up',
          style: {
            background: '#A2D9CE'
          },
          nextStatus: [2, 3, 5]
        },
        {
          id: 8,
          description: 'Atendendo',

          icon: 'fas fa-check',
          style: {
            background: '#FAD7A0'
          },
          nextStatus: [10]
        },
        {
          id: 9,
          description: 'Atender',
          icon: 'fas fa-notes-medical'
        },
        {
          id: 10,
          description: 'Continuar',
          icon: 'fas fa-notes-medical',
          nextStatus: []
        },
        {
          id: 11,
          description: 'Aguardando Triagem',

          icon: 'fas fa-user-nurse',
          style: {
            background: '#DED4B4'
          },
          nextStatus: this.user.responsavelTriagem === 0 ? [15] : []
        },
        {
          id: 12,
          description: 'Triagem em andamento',

          icon: 'fas fa-user-nurse',
          style: {
            background: '#DED4B4'
          },
          nextStatus: []
        },
        {
          id: 13,
          description: 'Triagem Finalizada',

          icon: 'fas fa-user-nurse',
          style: {
            background: '#DED4B4'
          },
          nextStatus: [9]
        },
        {
          id: 14,
          description: 'Aguardar Triagem',
          icon: 'fas fa-user-nurse',
          nextStatus: []
        },
        {
          id: 15,
          description: 'Realizar triagem',

          icon: 'fas fa-user-nurse',
          style: {
            background: '#DED4B4'
          },
          nextStatus: []
        },
        {
          id: 16,
          description: 'Confirmação Link',
          icon: 'fas fa-user-nurse',
          style: {
            background: '#A2D9CE'
          },
          nextStatus: [2, 3, 5]
        },
        {
          id: 17,
          description: 'Cancelado Link',
          icon: 'fas fa-user-nurse',
          style: {
            background: '#F5B7B1'
          },
          nextStatus: [0]
        },
        {
          id: 18,
          description: 'Internet Presencial',
          icon: 'fas fa-globe',
          nextStatus: [0]
        },
        {
          id: 19,
          description: 'Internet Teleconsulta',
          icon: 'fas fa-laptop',
          nextStatus: [0]
        }
      ]
    },
    formatedCancels() {
      if (this.patientInfo.listaCancelamentoPaciente) {
        let datas = ''
        this.patientInfo.listaCancelamentoPaciente.forEach((element) => {
          datas += element + '\n'
        })

        return `Últimos cancelamentos:\n${datas}`
      }
      return ''
    },

    formatedFaults() {
      if (this.patientInfo.listaFaltaPaciente) {
        let datas = ''
        this.patientInfo.listaFaltaPaciente.forEach((element) => {
          datas += element + '\n'
        })

        return `Últimas faltas:\n${datas}`
      }
      return ''
    },

    daysSinceLastAppointment() {
      const lastAppointment = this.patientInfo.dataUltimaConsulta
      if (lastAppointment) {
        const lastAppointmentJSDate = new Date(lastAppointment)
        const currentJSDate = new Date()
        const difTime =
          currentJSDate.getTime() - lastAppointmentJSDate.getTime()
        return Math.floor(difTime / (1000 * 3600 * 24)) // Retorna a diferença em dias
      }
      return 0 // Retorna 0 se não houver data
    }
  },
  watch: {
    dateToView: function (newDate, oldDate) {
      this.updateTotalizers()
    }
  },
  methods: {
    ...mapActions({
      setInfosTourOfUser: 'User/setInfosTourOfUser',
      getNotificationsAction: 'Notifications/getNotificationsAction',
      getPatient: 'Patient/getPatientAction',
      getClinic: 'Clinic/getClinicAction',
      sendLinkconfirmation: 'WaitingList/sendLinkconfirmation'
    }),

    openModalAgendInBlock() {
      this.openModalBlock = true
    },
    showProntuario() {
      if (
        this.user.idPerfil === 1 ||
        this.user.idPerfil === 4 ||
        this.user.idPerfil === 5
      ) {
        return true
      } else {
        return false
      }
    },
    async accessMedicalReport(medicalReport) {
      this.loading = true
      const date = `${new Date().toLocaleDateString('en-CA')}T${
        new Date().toTimeString().split(' ')[0]
      }Z`

      const object = {
        idStatusAgenda: 10,
        idTipoAgendamento: 4,
        dtInclusao: date,
        dtAgendamento: date,

        idPaciente: medicalReport.idPaciente,
        idUsuario: medicalReport.idUsuario,
        idUsuarioInclusao: medicalReport.idUsuario,
        pacienteNome: medicalReport.pacienteNome,
        idClinica: medicalReport.idClinica,
        origem: 'C',
        idUsuarioUltAtualizacao: this.user.id,
        dtUltAtualizacao: new Date().toISOString()
      }

      const apiResponse = await API.post('/api/Agenda/Adicionar', {
        object
      })

      const reqBody = {
        idAgenda: apiResponse.data.identificacao,
        idPaciente: medicalReport.idPaciente,
        idUsuario: medicalReport.idUsuario,
        idClinica: this.clinica.idClinica
      }

      const scheduleId = apiResponse.data.identificacao

      /* const scheduleApiResponse = await API.get(
        `/api/Agenda/ObtemItem/${scheduleId}`
      ) */

      await API.post(
        'api/Prontuario/Adicionar',
        { object: reqBody },
        this.authToken
      )
        .then((res) => {
          const { idPaciente, idUsuario, identificacao } = res.data
          this.$store
            .dispatch({
              type: 'MedicalReports/loadAllModules',
              payload: idUsuario
            })
            .then(() => {
              this.$store
                .dispatch({
                  type: 'MedicalReports/getAllModulesData',
                  payload: {
                    idProntuario: identificacao
                  }
                })
                .then(() => {
                  this.medicalReportBeingRequestedIndex = null

                  const requestData = {
                    idPaciente: window.btoa(this.encodeParamInfo(idPaciente)),
                    idUsuario: window.btoa(this.encodeParamInfo(idUsuario)),
                    identificacao: window.btoa(
                      this.encodeParamInfo(identificacao)
                    ),
                    scheduleId: window.btoa(this.encodeParamInfo(scheduleId))
                  }

                  this.$router.push({
                    name: 'prontuario.prontuario',
                    params: {
                      ...requestData
                    }
                  })
                })
                .catch((_) => {
                  this.loading = false
                })
            })
            .catch((_) => {
              this.loading = false
            })
        })
        .catch((_) => {
          this.loading = false
        })
    },

    async openPatientModal(schedule) {
      if (!schedule || !schedule.idPaciente) {
        this.$toast.error('Erro: schedule ou idPaciente está indefinido')
        return
      }

      const idPaciente = schedule.idPaciente

      this.selectedPatientId = idPaciente
      await this.getPatient(idPaciente)
      await this.getIndicators(idPaciente) // Verificando idPaciente antes da chamada

      this.showPatientHistoryModal = true
    },

    async getPatient(idPaciente) {
      try {
        const response = await API.get(
          `/api/Paciente/ObtemItem/${idPaciente}`,
          this.authToken
        )
        this.patientInfo = response.data.object
      } catch (error) {
        this.$toast.error('Erro ao buscar informações do paciente:', error)
      }
    },
    async getIndicators(idPatient) {
      await API.get(
        `api/Indicadores/IndicadorAgendaPaciente?id_paciente=${idPatient}`
      ).then((res) => {
        const indicators = res.data.object || {}
        // Verifica se há dados de indicators

        indicators.dataUltimaConsulta = indicators.dataUltimaConsulta || []
        if (indicators.dataUltimaConsulta.length > 0) {
          indicators.dataUltimaConsulta = this.formatDate(
            indicators.dataUltimaConsulta,
            'en-CA'
          )
        }

        indicators.listaCancelamentoPaciente =
          indicators.listaCancelamentoPaciente || []
        indicators.listaCancelamentoPaciente =
          indicators.listaCancelamentoPaciente.map((element) =>
            formatLocalDateHour(element)
          )

        indicators.listaFaltaPaciente = indicators.listaFaltaPaciente || []
        indicators.listaFaltaPaciente = indicators.listaFaltaPaciente.map(
          (element) => formatLocalDateHour(element)
        )

        // Formatação de listaHistoricoPaciente
        indicators.listaHistoricoPaciente =
          indicators.listaHistoricoPaciente || []
        indicators.listaHistoricoPaciente =
          indicators.listaHistoricoPaciente.map((element) => {
            if (element.dtHoraAgendamento) {
              element.dtHoraAgendamento = formatLocalDateHour(
                element.dtHoraAgendamento
              )
            } else {
              element.dtHoraAgendamento = 'Data não informada'
            }

            if (element.valorConsulta !== null) {
              element.valorConsulta = this.formatCurrency(element.valorConsulta)
            } else {
              element.valorConsulta = 'Não informado'
            }

            if (element.convenio === null) {
              element.convenio = 'Não informado'
            }

            return element
          })

        // Atribuindo os indicadores formatados ao patientInfo
        this.patientInfo = {
          ...this.patientInfo,
          ...indicators
        }
      })
    },
    formatCurrency(value) {
      if (value === null || value === undefined) return 'R$ 0,00'
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }).format(value)
    },

    resetPatientHistoryModal() {
      this.patientInfo = null // Limpa as informações do paciente ao fechar o modal
    },
    closeModal() {
      // Fecha o modal
      this.showHistoryModal = false
    },
    closeModalAgendInBlock() {
      this.openModalBlock = false
    },
    closeCancledModal() {
      this.modalCanceled = false
    },
    getModalRecorrencia(schedule, idUser) {
      this.modalRec = true
      this.dataReconrreciaSend.schedule = schedule
      this.dataReconrreciaSend.idUser = idUser
    },
    closeModalRecorrencia() {
      this.modalRec = false
      this.dataReconrreciaSend.schedule = null
      this.dataReconrreciaSend.idUser = null
    },
    getDisabled(item) {
      if (
        this.user.perfil_usuario?.idPerfil === 4 ||
        item === 4 ||
        item === 6 ||
        item === 18 ||
        item === 19
      ) {
        return true
      } else {
        return false
      }
    },
    getTestSignature(schedule) {
      const status =
        schedule.assinadoDigitalmente === 'S' ? 'Assinado' : 'Pendente'
      const data = moment(schedule.dtAssinadodigitalmente).format('DD/MM/YYYY')
      const hors = moment(schedule.dtAssinadodigitalmente).format('HH:mm')
      return `Assinado no dia ${data} as ${hors}. Indentificação do assinante: ${schedule.identificacaoAssinado} e status da assinatura: ${status}!`
    },
    async sendLinkConfirm(id) {
      this.loadingLinkConf = true
      const response = await this.sendLinkconfirmation(id)
      if (response) {
        this.loadingLinkConf = false
        this.$toast.success('Link enviado com sucesso!')
      } else {
        this.loadingLinkConf = false
        this.$toast.error(
          'Não foi possivel enviar o link! Tente novamente em alguns minutos'
        )
      }
    },
    verifySignature(schedule) {
      if (
        schedule.assinadoDigitalmente === null ||
        schedule.assinadoDigitalmente === 'N'
      ) {
        return true
      } else {
        return false
      }
    },
    finallyTuor() {
      const object = {
        idUsuario: this.user.id,
        dtCadastro: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        primeiroAcesso: 1,
        stepTour: 0
      }
      this.setInfosTourOfUser(object)
      this.$tours.tourFinally.stop()
      this.$tours.tourCreatePacient.stop()
      this.$tours.myTour.stop()
    },
    openModalAndFinshTour() {
      this.showToSchedule = true
      this.$tours.myTour.stop()
    },
    openTour() {
      const scriptElement = document.getElementById('step-tour')
      if (scriptElement === null) {
        this.$tours.myTour.start()
      } else {
        this.$tours.myTour.stop()
      }
    },
    handlerShowWaitingListModal() {
      this.showWaitingList = true
    },
    isIOSOrMac() {
      const userAgent = window.navigator.userAgent.toLowerCase()
      return /iphone|ipad|ipod|macintosh/i.test(userAgent)
    },
    async sendWhatsappConfirmationMessage(schedule) {
      try {
        const medicData = await API.get(
          `/api/Usuario/ObtemItem/${schedule.idUsuario}`,
          this.authToken
        )
        // Garanta que this.patientInfo está atualizado
        await this.getPatient(schedule.idPaciente)

        if (this.patientInfo && this.patientInfo.telCelular) {
          const clinicData = await this.getClinic(schedule.idClinica)
          const data = {
            number: this.patientInfo.telCelular,
            patientName: schedule.pacienteNome,
            date: formatDate(schedule.dtAgendamento, 'pt-BR').substring(0, 5),
            hour: schedule.hourFormated,
            doctorName: medicData.data.object.nome,
            idClinic: schedule.idClinica,
            idAgenda: schedule.id,
            clinic: {
              name: clinicData.descClinica,
              address: {
                street: clinicData.logradouro,
                neighbor: clinicData.bairro,
                city: clinicData.descCidade,
                state: clinicData.descSiglaEstado,
                number: clinicData.numero,
                complement: clinicData.complementoEndereco
              }
            }
          }
          scheduleConfirmationMessage(data)
        } else {
          this.$toast.error(
            'Este paciente não possui um número de celular cadastrado!'
          )
        }
      } catch (error) {
        this.$toast.error(
          'Erro ao enviar mensagem de confirmação pelo WhatsApp!'
        )
      }
    },
    onLinkCopy() {
      this.$toast.info('Link de telemedicina copiado')
    },
    onLinkCopyError() {
      this.$toast.error('Erro ao copiar link')
    },
    async getNotifications() {
      const object = {
        idUser: this.loggedUser.id,
        getConcludeds: 0,
        period: 7
      }

      await this.getNotificationsAction(object)
    },
    getNextStatusList(schedule) {
      const possibleNextStatus = this.handlerNextStatus(schedule.idStatusAgenda)
      const nextStatus = []
      const isHealthProfissional = [1, 4].includes(
        this.loggedUser.perfil_usuario[this.loggedUser.selectedProfileIndex]
          .idPerfil
      )
      if (isHealthProfissional) {
        nextStatus.push(...possibleNextStatus)
      } else {
        nextStatus.push(
          ...possibleNextStatus.filter((status) => ![9].includes(status))
        )
      }
      if (
        this.loggedUser.perfil_usuario[this.loggedUser.selectedProfileIndex]
          .idPerfil === 2
      ) {
        return nextStatus.filter((status) => status !== 10)
      }

      return nextStatus
    },
    updateTotalizers() {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.$refs.totalizers?.getIndicators(
          this.doctor.idUsuario,
          this.clinica.idClinica
        )
      }, 1000)
    },
    updateCalendar(date) {
      if (date) this.dateToView = date
      this.getScheduleListByDoctor(
        this.doctor.idUsuario,
        this.clinica.idClinica
      )
      this.updateTotalizers()
    },
    async sendVideoEmail(schedule) {
      try {
        const response = await API.get(`/api/Agenda/ReenviaEmailTeleConsulta`, {
          params: { id_agenda: schedule.id }
        })

        this.$toast.success(response.data.message)
      } catch {
        this.$toast.error('Falha ao enviar link de telemedicina')
      }
    },
    async request() {
      if (this.isManager) {
        await API.get(
          `/api/UsuarioClinica/ObtemUsuarioPorClinica/${this.user.clinica_usuario[0].idClinica}`,
          this.authToken
        ).then((res) => {
          this.doctors = res.data.object.filter(
            (user) =>
              user.perfil.id === 1 ||
              user.perfil.id === 4 ||
              user.perfil.id === 5
          )
          this.clinica = this.user.clinica_usuario[0]

          this.$nextTick(() => {
            if (!this.doctor) {
              this.doctor = this.doctors[0]
            }

            this.getScheduleListByDoctor(
              this.doctor.idUsuario,
              this.clinica.idClinica
            )
          })
        })
      } else {
        this.doctor = {
          idUsuario: this.user.id,
          utilizaProntuario: this.user.utilizaProntuario,
          nmUsuario: this.user.nome,
          agendamentoRecorrente: this.user.agendamentoRecorrente
        }
        if (!this.clinica) {
          this.clinica = this.user.clinica_usuario[0]
        }
        this.getScheduleListByDoctor(
          this.doctor.idUsuario,
          this.clinica.idClinica
        )
      }
      this.clinicas = this.user.clinica_usuario
    },
    getUser() {
      try {
        this.loadingUser = true
        API.get(
          `/api/Usuario/ObtemItem/${this.loggedUser.id}`,
          this.authToken
        ).then((res) => {
          this.user = res.data.object
          this.request()
          this.updateTotalizers()
          this.loadingUser = false
        })
      } catch (error) {
        this.$toast.error(error)
        this.$toast.error('Algo deu errado ao carregar seu usuário!')
      } finally {
        this.loadingUser = false
      }
    },
    nextDate() {
      const date = new Date(this.dateToView)

      this.dateToView = new Date(date.setDate(date.getDate() + 1))
        .toISOString()
        .substring(0, 10)

      this.getScheduleListByDoctor(
        this.doctor.idUsuario,
        this.clinica.idClinica
      )
    },
    forwardDate() {
      const date = new Date(this.dateToView)
      this.dateToView = new Date(date.setDate(date.getDate() - 1))
        .toISOString()
        .substring(0, 10)

      this.getScheduleListByDoctor(
        this.doctor.idUsuario,
        this.clinica.idClinica
      )
    },
    formatDate(date, format) {
      const newDateFormat = new Date(date)
      return newDateFormat.toLocaleDateString(format)
    },
    getArriveHour(date) {
      const newDate = new Date(date).toLocaleTimeString('pt-BR', {
        hour: '2-digit',
        minute: '2-digit'
      })
      return newDate
    },
    openModalWithHour(schedule) {
      if (
        schedule.descStatusAgenda === 'ATENDENDO' ||
        schedule.descStatusAgenda === 'CONTINUAR' ||
        schedule.descStatusAgenda === 'ATENDIDO'
      ) {
        this.schedule = schedule
        this.handlerShowToSchedule()
      } else {
        this.schedule = schedule
        this.handlerShowToSchedule()
      }
    },
    handlerShowFindScheduleModal() {
      if (!this.doctor) this.$toast.error('Selecione algum médico')
      else this.showFindScheduleModal = true
    },
    handlerShowFindPatient() {
      if (!this.doctor) this.$toast.error('Selecione algum médico')
      else if (!this.clinica) this.$toast.error('Selecione alguma clinica')
      else this.showFindPatient = true
    },
    handlerShowFindMedicalReportModal() {
      if (!this.doctor) this.$toast.error('Selecione algum médico')
      else if (!this.clinica) this.$toast.error('Selecione alguma clinica')
      else this.showFindMedicalReportModal = true
    },
    handlerShowToScheduleFromSearch(schedule) {
      this.hour = formatHour(schedule.hora_agendamento)
      this.clinica = this.clinicas.find((element) => {
        return element.idClinica === schedule.id_clinica
      })
      this.dateToView = this.formatDate(schedule.dt_Agendamento, 'en-CA')
      this.schedule = {
        hourFormated: formatHour(schedule.hora_agendamento),
        ...schedule
      }
      this.handlerShowToSchedule()
    },
    handlerShowToSchedule() {
      if (!this.doctor) this.$toast.error('Selecione algum médico')
      else if (!this.clinica) this.$toast.error('Selecione alguma clinica')
      else this.showToSchedule = true
    },
    handlerStatusIcon(statusId) {
      const status = this.statusList.find((status) => status.id === statusId)
      return status.icon
    },
    handlerColorByStatus(statusId) {
      const status = this.statusList.find((status) => status.id === statusId)
      return status.style
    },
    handlerNextStatus(statusId) {
      const status = this.statusList.find((status) => status.id === statusId)
      return status.nextStatus
    },
    showNextStatusDescription(statusId) {
      const status = this.statusList.find((status) => status.id === statusId)

      return status.description
    },
    async changeStatus(schedule, newStatus) {
      const actualDate = new Date()
      const scheduleDate = new Date(schedule.dtAgendamento)
      actualDate.setHours(0, 0, 0, 0)
      scheduleDate.setHours(0, 0, 0, 0)
      if (newStatus === 2 && scheduleDate > actualDate)
        return this.$toast.error(
          'Não é possivel colocar presente em data futuras'
        )
      newStatus === 9
        ? (schedule.idStatusAgenda = 8)
        : newStatus === 14
        ? (schedule.idStatusAgenda = 11)
        : newStatus === 15
        ? (schedule.idStatusAgenda = 12)
        : (schedule.idStatusAgenda = newStatus)
      if (newStatus === 0) {
        await API.delete(
          'api/Agenda/Remover',
          {
            data: {
              object: {
                id: schedule.id
              }
            }
          },
          this.authToken
        ).then((res) => this.updateTotalizers())
      } else if (newStatus === 6 || newStatus === 18 || newStatus === 19) {
        const scheduleToAdd = {
          idClinica: this.clinica.idClinica,
          idUsuario: this.doctor.idUsuario,
          dtAgendamento: `${this.dateToView}T${formatHour(
            schedule.horaAgendamento
          )}`,
          idStatusAgenda: newStatus,
          idUsuarioUltAtualizacao: this.user.id,
          dtUltAtualizacao: new Date().toISOString()
        }
        await API.post(
          '/api/Agenda/Adicionar',
          {
            object: scheduleToAdd
          },
          this.authToken
        ).then((res) => this.updateTotalizers())
      } else {
        if (newStatus === 2) {
          schedule.dtChegada = moment(new Date()).format(
            'YYYY-MM-DDTHH:mm:ss.SSSSSS'
          )
        }
        if (newStatus === 10) {
          schedule.idStatusAgenda = 8
        }
        await API.put(
          '/api/Agenda/Editar',
          {
            object: {
              ...schedule,
              dtChegada: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSSSSS')
            }
          },
          this.authToken
        ).then((res) => {
          this.updateTotalizers()
          this.createMedicalReport(schedule, newStatus)
        })
      }
      if (newStatus === 5) {
        if (
          schedule.idRecorrenciaAgendaOrigem !== null &&
          schedule.idRecorrenciaAgendaOrigem !== undefined
        ) {
          this.idAgendamentoCancel = schedule.id
          this.modalCanceled = true
        } else {
          this.getScheduleListByDoctor(
            this.doctor.idUsuario,
            this.clinica.idClinica
          )
        }
      } else {
        this.getScheduleListByDoctor(
          this.doctor.idUsuario,
          this.clinica.idClinica
        )
      }
    },
    showIgnoreTriageModal(schedule) {
      this.scheduleToIgnoreTriage = schedule
      this.$bvModal.show('ignoreTriageModal')
    },
    canceledModal() {
      this.getScheduleListByDoctor(
        this.doctor.idUsuario,
        this.clinica.idClinica
      )
    },
    async getScheduleListByDoctor(doctorId, idClinica) {
      this.loading = true
      await API.get(
        `/api/Agenda/ObtemListaPorMedicoDia/?id_medico=${doctorId}&id_clinica=${idClinica}&data_ref=${this.dateToView}`,
        this.authToken
      )
        .then((res) => {
          if (res.data.object.length > 0) {
            this.scheduleList = res.data.object.map((schedule) => ({
              hourFormated: formatHour(schedule.horaAgendamento),
              ...schedule,
              // eslint-disable-next-line no-constant-condition
              idStatusAgenda:
                schedule.idStatusAgenda === 10 ? 8 : schedule.idStatusAgenda
            }))
            this.scheduleList.sort((a, b) =>
              a.hourFormated > b.hourFormated ? 1 : -1
            )
          } else {
            this.$toast.info('Não há horário cadastrado para esse dia!')
            this.scheduleList = ''
          }
          this.$refs.totalizers?.getIndicators(doctorId, idClinica)
          this.loading = false
        })
        // eslint-disable-next-line handle-callback-err
        .catch((err) => {
          this.$toast.info('Não há horário cadastrado para esse dia!')
          this.scheduleList = ''
          this.loading = false
        })
    },
    modalClosed(modal) {
      modal === 'agendar-paciente'
        ? (this.showToSchedule = false)
        : modal === 'find-patient'
        ? (this.showFindPatient = false)
        : modal === 'find-medical-report'
        ? (this.showFindMedicalReportModal = false)
        : (this.showFindScheduleModal = false)
      this.schedule = ''
    },

    encodeParamInfo(n) {
      const encode = (n * 100) / 40
      return encode
    },
    async createMedicalReport(schedule, nextStatus) {
      if (nextStatus === 10) {
        try {
          this.loadingUser = true

          const apiResponse = await API.get(
            `/api/Prontuario/ObtemItemPorAgenda/${schedule.id}`
          )

          if (apiResponse.data.result) {
            const {
              idPaciente,
              idUsuario,
              id: identificacao
            } = apiResponse.data.object

            await this.$store.dispatch({
              type: 'MedicalReports/getAllModulesData',
              payload: {
                idProntuario: identificacao
              }
            })

            this.loadingUser = false
            const requestData = {
              idPaciente: window.btoa(this.encodeParamInfo(idPaciente)),
              idUsuario: window.btoa(this.encodeParamInfo(idUsuario)),
              identificacao: window.btoa(this.encodeParamInfo(identificacao)),
              scheduleId: window.btoa(this.encodeParamInfo(schedule.id))
            }

            this.$router.push({
              name: 'prontuario.prontuario',
              params: {
                ...requestData
              }
            })
          }
        } catch (error) {}
      }
      if (nextStatus === 9) {
        this.loadingUser = true
        const reqBody = {
          idAgenda: schedule.id,
          idPaciente: schedule.idPaciente,
          idUsuario: schedule.idUsuario,
          idClinica: this.clinica.idClinica
        }

        const apiResponse = await API.post(
          `api/Prontuario/Adicionar`,
          { object: reqBody },
          this.authToken
        )
        const { idPaciente, idUsuario, identificacao } = apiResponse.data

        this.$store
          .dispatch({
            type: 'MedicalReports/getAllModulesData',
            payload: {
              idProntuario: identificacao
            }
          })
          .then(() => {
            this.loadingUser = false

            const requestData = {
              idPaciente: window.btoa(this.encodeParamInfo(idPaciente)),
              idUsuario: window.btoa(this.encodeParamInfo(idUsuario)),
              identificacao: window.btoa(this.encodeParamInfo(identificacao)),
              scheduleId: window.btoa(this.encodeParamInfo(schedule.id))
            }

            this.$router.push({
              name: 'prontuario.prontuario',
              params: {
                ...requestData
              }
            })
          })
          .catch((_) => {})
      }

      if (nextStatus === 15) {
        const triageScheduleObject = {
          ...schedule,
          idUsuario: this.user.id,
          idAgendaOrigemTriagem: schedule.id,
          dtInicioTriagem: formatDateHour(),
          idUsuarioInclusao: this.user.id,
          idUsuarioTriagem: this.user.id,
          dtAgendamento: formatDateHour(),
          idStatusAgenda: 10,
          idUsuarioUltAtualizacao: this.user.id,
          dtUltAtualizacao: new Date().toISOString()
        }

        delete triageScheduleObject.id

        const triageScheduleRequest = await API.post(
          'api/Agenda/Adicionar',
          {
            object: triageScheduleObject
          },
          this.authToken
        )
        const triageScheduleId = triageScheduleRequest.data.identificacao
        this.loadingUser = true
        const reqBody = {
          idAgenda: triageScheduleId,
          idPaciente: schedule.idPaciente,
          idUsuario: this.user.id,
          idClinica: this.clinica.idClinica
        }

        const apiResponse = await API.post(
          `api/Prontuario/Adicionar`,
          { object: reqBody },
          this.authToken
        )
        const { idPaciente, idUsuario, identificacao } = apiResponse.data

        this.$store
          .dispatch({
            type: 'MedicalReports/getAllModulesData',
            payload: {
              idProntuario: identificacao
            }
          })
          .then(() => {
            this.loadingUser = false

            const requestData = {
              idPaciente: window.btoa(this.encodeParamInfo(idPaciente)),
              idUsuario: window.btoa(this.encodeParamInfo(idUsuario)),
              identificacao: window.btoa(this.encodeParamInfo(identificacao)),
              scheduleId: window.btoa(this.encodeParamInfo(triageScheduleId))
            }

            this.$router.push({
              name: 'prontuario.prontuario',
              params: {
                ...requestData
              }
            })
          })
          .catch((_) => {})
      }
    }
  }
}
</script>

<style scoped>
input[readonly] {
  background: white;
}
.history {
  background-color: #089bab;
  color: white;
  font-weight: 500;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border-radius: 7px;
  margin-right: 3px;
}
.no-history-message {
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
  color: #888;
}

.input-custom {
  margin: 0;
  padding-left: 6px;
  padding-bottom: 0;
  padding-top: 0;
  padding-right: 0;
  height: 33px;
}

.input-last-appointment {
  background: #5dade2 !important;
  color: #fff !important;
  text-align: center !important;
}

.input-since-last-appointment {
  background: #22b14c !important;
  color: #fff !important;
  text-align: center !important;
}

.input-cancellations {
  background: #f5b041 !important;
  color: #fff !important;
  text-align: center !important;
}

.input-faults {
  background: #ec7063 !important;
  color: #fff !important;
  text-align: center !important;
}
.warning-text {
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
}
.selectSize {
  height: 35px !important;
}
ul {
  padding: 0 !important;
}
.sameLevel li {
  cursor: pointer;
  list-style-type: none;
  font-size: 20px;
  color: #777d74;
  margin: 0 1px 0 0;
  float: left;
  padding: 0;
}

.centralizeText {
  text-align: center;
}

i.fas,
i.fab,
i.fa {
  color: #089bab;
  font-weight: 900;
}
.iq-card_scoped {
  transition: box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  max-height: 80vh !important;
}

.headerForm {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  justify-content: flex-start;
  align-items: center;
}

.totalizersHandler {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.totalizersHandler i {
  margin-left: 10px;
}

.header {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 0;
}

.cardSameLevel {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.sameLevel {
  display: flex;
  align-items: center;
}

.iconDescription {
  margin-left: 4px;
}

.sameLevelGrid {
  display: grid;
  grid-template-columns: 2fr 3fr 1.5fr 1fr 1fr;
  gap: 20px;
  align-items: center;
  margin-bottom: 2%;
}

.radioOptions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form {
  height: auto;
  overflow-x: auto;
}

.buttons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;
}
.table-container {
  margin-top: 10px;
  max-height: 300px; /* Altura máxima da tabela antes de aplicar a rolagem */
  overflow-y: auto; /* Adiciona rolagem vertical quando o conteúdo exceder a altura máxima */
  border-radius: 4px;
}

.table {
  border-radius: 6px;
  overflow-y: auto;
}

.tableHeight1 {
  height: 60vh;
}

.tableHeight2 {
  height: 40vh;
}

.buttons-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.headerList {
  position: sticky;
  top: 0;
  font-weight: 500;
  height: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1.2fr 0.8fr 0.8fr 1fr 1.4fr 0.1fr;
  gap: 10px;
  border-bottom: 2px solid #c4c8d1;
  justify-content: space-between;
  text-align: flex-start;
  align-items: center;
  background: #089bab;
  color: #fff;
}

.dataList {
  z-index: 2;
  font-weight: 400;
  display: grid;
  padding-top: 8px;
  grid-template-columns: 0.8fr 1.2fr 1.3fr 0.6fr 1fr 1.3fr 0fr 1.1fr;
  gap: 10px;
  text-align: start;
  align-items: center;
  color: #000;
  border-bottom: 2px solid #c4c8d1;

  transition: box-shadow 0.3s;
}

.showNextStatus {
  display: none;

  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

.onHover:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  padding-bottom: 10px;
}

.onHover:hover .showNextStatus {
  display: flex;
  height: 36px;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.onHover:hover .scheduleDetails {
  display: none;
}

.scheduleDetails > i {
  margin-left: 6px; /* Ajuste o valor conforme necessário */
}
.statusBox {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  background: #eff7f8;
  border-radius: 7px;
  margin-right: 3px;
}

.statusBox i {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.statusBox i span {
  text-align: center;
  white-space: nowrap;
}

.statusBox:hover {
  background: #f2f2f2;
  cursor: pointer;
}

.statusBox:hover i {
  color: #01424a;
}

.statusBox i {
  margin-right: 10px;
}

.buttonBottom {
  bottom: 0;
  right: 0;
}

.title-tour {
  display: flex;
  align-items: center;
  gap: 8px;
}

.imgSignature {
  width: 20px;
  height: 20px;
}
#button-hour {
  margin-left: 2px;
  padding: 4px;
  border: none;
  background-color: #089bab;
  color: #eff7f8;
  border-radius: 40px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
.tooltip {
  opacity: 1 !important;
}

@media (max-width: 460px) {
  .buttons-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 10px;
  }
  #buttons-container {
    padding: 5px;
  }
  .imgSignature {
    width: 10px;
    height: 10px;
  }
  .headerList {
    z-index: 1;
    position: sticky;
    top: 0;
    font-weight: 500;
    height: 40px;
    display: grid;
    grid-template-columns: 1fr 1.4fr repeat(6, 1fr) 0.3fr 0.1fr;
    gap: 10px;
    border-bottom: 2px solid #c4c8d1;
    justify-content: space-between;
    text-align: flex-start;
    align-items: center;
    background: #089bab;
    color: #fff;
  }

  .centralizeText {
    margin-left: 6px;
  }

  .headerList .list {
    font-size: 0.3rem;
  }

  .table span {
    font-size: 0.4rem;
    background-color: 'red';
  }

  #button-hour {
    font-size: 0.5rem;
  }
  .statusBox {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 18px;
    background: #eff7f8;
    margin: 0px;
  }

  .statusBox i {
    display: flex;
    align-items: center;
  }
}

.v-step[data-v-54f9a632] {
  max-width: none;
}

#step-tour {
  width: 440px;
  background-color: #089bab;
  border: 1px solid #089bab;
  color: #089bab;
  padding: 0px;
}

#step-tour4 {
  width: 440px;
  background-color: #089bab;
  border: 1px solid #089bab;
  color: #089bab;
  padding: 0px;
}

#header-step {
  background-color: #f7f7f7;
  width: 700px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-bottom: #089bab 1px solid;
  padding: 6px 10px;
}

#content-step {
  background-color: #f7f7f7;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  padding: 6px 10px;
}

#content-step span {
  color: #000;
  font-size: 14px;
  font-weight: 500;
}

#title-text {
  font-size: 12px;
  font-weight: normal;
  color: #2b2b2b;
  text-align: start;
  padding: 0px;
  margin: 0px;
}

#footer-step {
  background-color: #f7f7f7;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 6px 10px;
}

#butons-step {
  display: flex;
  gap: 10px;
  align-items: center;
}

#button-step-fin {
  background-color: transparent;
  border: none;
  color: #bcb9b9;
  font-weight: bold;
}

.button-cancel {
  font-size: 0.8rem;
  padding: 6px 12px;
  color: red;
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid red;
}

#button-step {
  border-radius: 10px;
  padding: 6px 10px;
  background-color: #089bab;
  color: #fff;
  font-weight: bold;
}

#step-image {
  width: 100%;
  height: 200px;
}

@media (max-width: 370px) {
  #buttons-container {
    font-size: 0.6rem !important;
  }
}
</style>
