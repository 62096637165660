<template>
  <b-container fluid>
    <b-row>
      <b-col class="userListColumn" lg="3">
        <div class="usersList">
          <div
            v-for="clinic in userClinics"
            :key="clinic.id"
            @click="handlerShow(clinic)"
            class="onHover"
          >
            <iq-card
              :class="[
                'iq - card_scoped',
                clinic.idClinica === newClinic.id ? 'selected' : ''
              ]"
            >
              <template v-slot:headerTitle>
                <div class="cardSameLevel">
                  <span class="card-title">{{ clinic.nmClinica }}</span>
                </div>
              </template>
            </iq-card>
          </div>
        </div>
        <div class="buttonBottom">
          <b-button
            @click="handlerShow()"
            variant="success"
            v-if="userHaveHighPermission"
            >Adicionar</b-button
          >
        </div>
      </b-col>
      <b-col lg="9" v-show="show">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <form
            class="form"
            novalidate
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <iq-card class="form-iqcard">
              <template v-slot:headerTitle>
                <div class="headerForm">
                  <b-form-radio-group v-model="registerOption">
                    <b-form-radio name="newClinic" value="register"
                      >Criar minha clinica</b-form-radio
                    >
                    <b-form-radio name="existClinic" value="search"
                      >Buscar clinica existente</b-form-radio
                    >
                  </b-form-radio-group>
                  <div class="iq-search-bar">
                    <form action="#" class="searchbox" style="margin-top: 10px">
                      <input
                        type="text"
                        class="text search-input"
                        placeholder="Digite o código da clinica"
                        v-model="clinicVoucher"
                        :readonly="registerOption === 'search' ? false : true"
                      />
                      <div class="search-link">
                        <i
                          @click="
                            registerOption === 'search' ? searchClinic() : null
                          "
                          v-if="!loading"
                          class="ri-search-line"
                        ></i>
                        <b-spinner
                          v-else
                          class="alignCenter"
                          small
                          variant="primary"
                        ></b-spinner>
                      </div>
                    </form>
                  </div>
                  <div class="checkbox-container">
                    <div class="itens-checkbox">
                      <b-form-checkbox
                        v-model="newClinic.compartilhamentoProntuario"
                        :value="0"
                        :unchecked-value="1"
                      ></b-form-checkbox>
                      <span>Compartilhado</span>
                    </div>
                    <div class="itens-checkbox">
                      <b-form-checkbox
                        v-model="newClinic.ativaTabServicoProprio"
                        :value="0"
                        :unchecked-value="1"
                      ></b-form-checkbox>
                      <span>Tabela própria de Exames/Serviço</span>
                    </div>
                  </div>
                  <button
                    class="button-gerar"
                    v-if="
                      newClinic.linkClinicaAgOnline === null && handleAcess()
                    "
                    @click.prevent="gerarLinkOnlineClinic(newClinic.id)"
                  >
                    Gerar Link Ag. Online
                  </button>

                  <button
                    class="button-copiar"
                    v-if="
                      newClinic.linkClinicaAgOnline !== null && handleAcess()
                    "
                    @click.prevent="copiarTexto('clinic')"
                  >
                    Copiar Link Ag. Online
                  </button>
                </div>
              </template>
              <template v-slot:body>
                <div class="new-user-info">
                  <b-row>
                    <b-form-group class="col-md-3">
                      <div class="photo">
                        <img
                          :src="
                            avatarUrl
                              ? avatarUrl
                              : avatar
                              ? avatar.result
                              : require('../../assets/images/user/2.png')
                          "
                          :class="
                            avatar || avatarUrl ? 'avatar-100' : 'avatar-50'
                          "
                        />
                        <input
                          type="file"
                          ref="fileInput"
                          style="display: none"
                          v-on:change="changeImage"
                        />
                        <b-button
                          size="sm"
                          variant="primary"
                          @click="$refs.fileInput.click()"
                          >Selecionar foto</b-button
                        >
                      </div>
                    </b-form-group>
                    <b-form-group
                      class="col-md-3"
                      label="Código:"
                      label-for="code"
                    >
                      <b-form-input
                        v-model="newClinic.codigo"
                        type="text"
                        :readonly="true"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-6"
                      label="Nome Comercial:"
                      label-for="descClinica"
                    >
                      <ValidationProvider
                        name="descClinica"
                        rules="required|min:3"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          v-model="newClinic.descClinica"
                          name="descClinica"
                          type="text"
                          v-uppercase
                          :autofocus="true"
                          :class="errors.length > 0 ? ' is-invalid' : ''"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          <span>Informe o nome comercial</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group
                      class="col-md-3"
                      label="CNES:"
                      label-for="cnes"
                    >
                      <ValidationProvider
                        name="Cnes"
                        rules="required|digits:7"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          v-model="newClinic.cnes"
                          type="text"
                          :class="errors.length > 0 ? ' is-invalid' : ''"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          <span>Informe o CNES válido</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>

                    <b-form-group
                      class="col-md-3"
                      label="CNPJ:"
                      label-for="cnpj"
                    >
                      <ValidationProvider
                        name="Cnpj"
                        rules="cnpjIsValid"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          v-model="newClinic.cnpj"
                          v-mask="'##.###.###/####.##'"
                          type="text"
                          name="cnpj"
                          :class="errors.length > 0 ? ' is-invalid' : ''"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          <span>Informe um cnpj válido</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>

                    <b-form-group
                      class="col-md-6"
                      label="Email Responsável:"
                      label-for="emailResponsible"
                    >
                      <ValidationProvider
                        name="EmailResponsible"
                        rules="email"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          v-model="newClinic.emailClinica"
                          type="email"
                          v-uppercase
                          :class="errors.length > 0 ? ' is-invalid' : ''"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          <span>Informe um email válido</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group
                      class="col-sm-3"
                      label-for="idTipoLogradouro"
                      label="Tipo endereço:"
                    >
                      <v-select
                        v-model="newClinic.idTipoLogradouro"
                        :options="adressTypes"
                        :reduce="(descLogradouro) => descLogradouro.id"
                        label="descLogradouro"
                      ></v-select>
                    </b-form-group>
                    <b-form-group class="col-md-3" label="CEP:" label-for="cep">
                      <b-form-input
                        v-model="newClinic.cep"
                        type="text"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-6"
                      label="Logradouro:"
                      label-for="logradouro"
                    >
                      <b-form-input
                        v-model="newClinic.logradouro"
                        type="text"
                        v-uppercase
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-2"
                      label="Nº:"
                      label-for="numero"
                    >
                      <b-form-input
                        v-model="newClinic.numero"
                        type="number"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-5"
                      label="Complemento:"
                      label-for="complementoEndereco"
                    >
                      <b-form-input
                        v-model="newClinic.complementoEndereco"
                        type="text"
                        v-uppercase
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-5"
                      label="Bairro:"
                      label-for="bairro"
                    >
                      <b-form-input
                        v-model="newClinic.bairro"
                        type="text"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-5"
                      label-for="idEstado"
                      label="Estado:"
                    >
                      <v-select
                        v-model="newClinic.idEstado"
                        :options="states"
                        :reduce="(descEstado) => descEstado.id"
                        label="descEstado"
                        @input="handleChangeState"
                      ></v-select>
                    </b-form-group>
                    <b-form-group
                      class="col-md-5"
                      label-for="idCidade"
                      label="Cidade:"
                    >
                      <v-select
                        v-model="newClinic.idCidade"
                        :options="cities"
                        :reduce="(descCidade) => descCidade.id"
                        label="descCidade"
                      ></v-select>
                    </b-form-group>
                    <b-form-group
                      class="col-md-2"
                      label="Telefone:"
                      label-for="telefone"
                    >
                      <b-form-input
                        v-model="newClinic.telefone"
                        v-mask="'(##)# #### - ####'"
                        type="text"
                      ></b-form-input>
                    </b-form-group>
                  </b-row>
                  <hr />
                  <h5 class="mb-3">Usuários</h5>
                  <b-row>
                    <div class="table">
                      <div class="headerList">
                        <span>Código</span>
                        <span>Nome</span>
                        <span>Função</span>
                        <span
                          style="
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          "
                          >Ação</span
                        >
                      </div>
                      <div
                        class="dataList"
                        v-for="user in usersByClinic"
                        :key="user.id"
                      >
                        <span>{{ user.idUsuario }}</span>
                        <span>{{ user.nmUsuario }}</span>
                        <span>{{ user.perfil.descPerfil }}</span>
                        <div
                          style="
                            display: flex;
                            align-items: center;
                            gap: 4px;
                            justify-content: center;
                          "
                        >
                          <button
                            class="button-gerar"
                            style="padding: 4px 10px; margin: 0px"
                            v-if="
                              user.linkUsuarioAgOnline === null &&
                              user.perfil.id !== 2 &&
                              user.perfil.id !== 3
                            "
                            @click.prevent="gerarLinkOnlineDoctor(user)"
                          >
                            Gerar Link Ag. Online
                          </button>

                          <button
                            style="padding: 4px 10px; margin: 0px"
                            class="button-copiar"
                            v-if="
                              user.linkUsuarioAgOnline !== null &&
                              user.perfil.id !== 2 &&
                              user.perfil.id !== 3
                            "
                            @click.prevent="
                              copiarTexto('doctor', user.linkUsuarioAgOnline)
                            "
                          >
                            Copiar Link Ag. Online
                          </button>
                          <b-button
                            v-if="verifyIfCanRemove(user.idUsuario)"
                            variant="outline-danger"
                            size="sm"
                            v-b-modal.modal-remove
                            >Remover</b-button
                          >
                          <b-modal
                            id="modal-remove"
                            ref="modal-remove"
                            hide-footer
                            title="Essa ação é permanente"
                            cancel-title="Não"
                            ok-title="Sim"
                          >
                            <p class="my-2">
                              Você realmente deseja remover esse usuário?
                            </p>
                            <div class="buttons">
                              <b-button
                                class="mt-2"
                                size="sm"
                                variant="danger"
                                @click="$bvModal.hide('modal-remove')"
                                >Não</b-button
                              >
                              <b-button
                                class="mt-2"
                                size="sm"
                                variant="primary"
                                @click="removeUser(user)"
                                >Sim</b-button
                              >
                            </div>
                          </b-modal>
                        </div>
                      </div>
                    </div>
                  </b-row>
                  <div class="buttons">
                    <b-button variant="danger" v-b-modal.modal-confirm
                      >Cancelar</b-button
                    >
                    <b-modal
                      id="modal-confirm"
                      ref="modal-confirm"
                      hide-footer
                      title="Todos os dados não salvos serão perdidos"
                      cancel-title="Não"
                      ok-title="Sim"
                    >
                      <p class="my-2">Você realmente deseja cancelar?</p>
                      <div class="buttons">
                        <b-button
                          class="mt-2"
                          size="sm"
                          variant="danger"
                          @click="hideModal"
                          >Não</b-button
                        >
                        <b-button
                          class="mt-2"
                          size="sm"
                          variant="primary"
                          @click="cancelar"
                          >Sim</b-button
                        >
                      </div>
                    </b-modal>
                    <b-button variant="primary" type="submit">Salvar</b-button>
                  </div>
                </div>
              </template>
            </iq-card>
          </form>
        </ValidationObserver>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapGetters } from 'vuex'

import { cnpj } from 'cpf-cnpj-validator'

import API from '../../services/index'

import iqCard from '../../components/xray/cards/iq-card.vue'

export default {
  components: { iqCard },
  name: 'AddClinic',
  mounted() {
    this.getClinics()
    API.get('/api/TipoLogradouro/ObtemLista', this.authToken).then((res) => {
      this.adressTypes = res.data.object
    })

    API.get('/api/Estado/ObtemLista', this.authToken).then((res) => {
      this.states = res.data.object
    })
  },
  data() {
    return {
      buttonnToggle: false,
      authToken: {
        headers: { Authorization: `Bearer ${window.$cookies.get('token')}` }
      },
      isShared: 1,
      show: true,
      registerOption: 'register',
      isAdd: true,
      userClinics: [],
      usersByClinic: [],
      clinicVoucher: '',
      states: [],
      cities: [],
      newClinic: {
        codigo: '',
        descClinica: '',
        cnes: '',
        cnpj: '',
        responsible: '',
        emailClinica: '',
        idTipoLogradouro: '',
        cep: '',
        logradouro: '',
        numero: '',
        complementoEndereco: '',
        bairro: '',
        idEstado: '',
        idCidade: '',
        telefone: '',
        compartilhamentoProntuario: 1,
        ativaTabServicoProprio: 1
      },
      adressTypes: [],
      loading: false,
      avatar: '',
      avatarImage: '',
      avatarUrl: ''
    }
  },
  computed: {
    ...mapGetters({
      user: 'Setting/user'
    }),
    userHaveHighPermission() {
      const ids = [1, 3]
      return ids.includes(
        this.user.perfil_usuario[this.user.selectedProfileIndex].idPerfil
      )
    }
  },
  methods: {
    handleAcess() {
      if (
        this.user.perfil_usuario[this.user.selectedProfileIndex].idPerfil ===
          1 ||
        this.user.perfil_usuario[this.user.selectedProfileIndex].idPerfil ===
          4 ||
        this.user.perfil_usuario[this.user.selectedProfileIndex].idPerfil === 5
      ) {
        return true
      } else {
        return false
      }
    },
    changeImage(e) {
      this.avatarImage = e.target.files[0]
      this.avatarUrl = URL.createObjectURL(this.avatarImage)
    },
    verifyIfCanRemove(userId) {
      if (
        [2, 3].includes(
          this.user.perfil_usuario[this.user.selectedProfileIndex].idPerfil
        )
      ) {
        return true
      }

      return userId === this.user.id
    },
    handlerShow(clinic) {
      if (clinic) {
        this.newClinic = { ...clinic }
        this.isAdd = false
      } else {
        this.resetForm()
        this.isAdd = true
      }
      this.requests(clinic?.idClinica)
      this.show = true
    },
    handleChangeState() {
      this.newClinic.idCidade = ''
      this.getCities()
    },

    async gerarLinkOnlineClinic(id) {
      try {
        const response = await API.get(
          `/api/Clinica/GeraLinkAgendamentoOnlineClinica?id_clinica=${id}`
        )

        this.newClinic.linkClinicaAgOnline = response.data.object
      } catch (err) {}
    },

    async gerarLinkOnlineDoctor(item) {
      try {
        const response = await API.get(
          `/api/UsuarioClinica/GeraLinkAgendamentoOnlineUsuario?id_clinica=${item.idClinica}&id_usuario=${item.idUsuario}`
        )

        item.linkUsuarioAgOnline = response.data.object
      } catch (err) {}
    },
    copiarTexto(value, object) {
      if (!window.location.href.includes('app.doctorpep.com.br')) {
        this.newClinic.linkClinicaAgOnline =
          this.newClinic.linkClinicaAgOnline.replace(
            'https://app.doctorpep.com.br',
            'https://doctorpep.vercel.app'
          )
      }
      if (value === 'clinic') {
        navigator.clipboard
          .writeText(this.newClinic.linkClinicaAgOnline)
          .then(() => {
            this.$toast.success('Link copiado com sucesso!')
          })
          .catch((err) => {
            this.$toast.error('Erro ao copiar o link!')
          })
      }
      if (value === 'doctor') {
        if (!window.location.href.includes('app.doctorpep.com.br')) {
          object = object.replace(
            'https://app.doctorpep.com.br',
            'https://doctorpep.vercel.app'
          )
        }
        navigator.clipboard
          .writeText(object)
          .then(() => {
            this.$toast.success('Link copiado com sucesso!')
          })
          .catch((err) => {
            this.$toast.error('Erro ao copiar o link!')
          })
      }
    },
    async requests(id) {
      this.resetForm()

      if (id) {
        const response = await API.get(
          `/api/Clinica/ObtemItem/${id}`,
          this.authToken
        )

        const clinic = response.data.object
        this.newClinic = response.data.object

        if (clinic.idEstado) {
          this.getCities()
        }

        const users = await API.get(
          `/api/UsuarioClinica/ObtemUsuarioPorClinica/${id}`,
          this.authToken
        )

        this.usersByClinic = users.data.object
        if (clinic.descArquivoImagem) {
          API.get(
            `api/Clinica/AwsS3ObtemArquivo?arquivoname=${clinic.descArquivoImagem}&id_clinica=${id}`,
            { responseType: 'blob' },
            {
              headers: {
                Authorization: `Bearer ${window.$cookies.get('token')}`
              }
            }
          ).then((res) => {
            this.createFileReader(res.data)
          })
        }
      }
    },
    resetForm() {
      this.newClinic = {
        code: '',
        descClinica: '',
        cnes: '',
        cnpj: '',
        responsible: '',
        emailResponsible: '',
        idTipoLogradouro: '',
        cep: '',
        logradouro: '',
        numero: '',
        complementoEndereco: '',
        bairro: '',
        idEstado: '',
        idCidade: '',
        telefone: '',
        compartilhamentoProntuario: 1,
        descArquivoImagem: ''
      }
      this.usersByClinic = ''
      this.cities = ''
      this.avatar = ''
      this.avatarImage = ''
      this.avatarUrl = ''
    },
    formatTelefone() {
      const newTelefone = this.newClinic.telefone.replace(/[^0-9]/g, '')
      this.newClinic.telefone = newTelefone
    },
    formatCnpj() {
      const newCnpj = cnpj.strip(this.newClinic.cnpj)
      this.newClinic.cnpj = newCnpj
    },
    hideModal() {
      this.$refs['modal-confirm'].hide()
    },
    cancelar() {
      this.hideModal()
      this.show = false
    },
    async getClinics() {
      await API.get(
        `/api/UsuarioClinica/ObtemClinicaPorUsuario/${this.user.id}`,
        this.authToken
      ).then((res) => {
        this.userClinics = res.data.object
      })
    },
    async getCities() {
      await API.get(
        `/api/Cidade/ObtemCidadesPorEstado/${this.newClinic.idEstado}`,
        this.authToken
      ).then((res) => {
        this.cities = res.data.object
      })
    },
    createFileReader(file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)

      reader.onloadend = (e) => {
        this.avatar = reader
      }

      return reader
    },
    searchClinic() {
      this.loading = true
      API.get(
        `/api/Clinica/ObtemClnicaVoucher/ ?voucher=${this.clinicVoucher}`,
        this.authToken
      ).then((res) => {
        if (res.data.object) {
          this.resetForm()
          this.newClinic = res.data.object
          this.getCities()
        } else {
          this.$toast.error('Clinica não encontrada')
        }
        this.isAdd = false
        this.loading = false
      })
    },
    async removeUser(user) {
      const newData = this.usersByClinic.filter(
        (userClinic) => userClinic.idUsuario !== user.idUsuario
      )
      this.usersByClinic = newData

      await API.delete('/api/UsuarioClinica/Remover', {
        data: {
          object: {
            id: user.id,
            idUsuario: user.idUsuario,
            nmUsuario: user.nmUsuario,
            idClinica: user.idClinica,
            nmClinica: user.nmClinica,
            perfil: {
              id: user.perfil.id,
              descPerfil: user.perfil.descPerfil,
              status: 0
            }
          }
        },
        ...this.authToken
      }).then(this.$bvModal.hide('modal-remove'))
    },
    onSubmit() {
      if (this.newClinic.telefone) {
        this.formatTelefone()
      }

      if (this.newClinic.cnpj) {
        this.formatCnpj()
      }
      this.newClinic.idUsuario = this.user.id

      let file = ''
      let fileType = ''
      let fileName = ''
      let bodyFormData = ''

      if (this.avatarImage) {
        file = {
          file: this.avatarImage,
          tipoArquivoImagem:
            this.avatarImage.type === 'image/jpeg' ||
            this.avatarImage.type === 'image/png'
              ? 'image'
              : this.avatarImage.type === 'application/pdf'
              ? 'pdf'
              : 'doc',
          descAwsArquivoImagem: this.avatarImage.name
        }
        bodyFormData = new FormData()
        bodyFormData.append('file', file.file)
        fileType =
          file.file.type === 'image/jpeg'
            ? 'image'
            : file.file.type === 'application/pdf'
            ? 'pdf'
            : 'doc'

        fileName = file.file.name
      }

      this.$store
        .dispatch(`Clinic/${this.isAdd ? 'add' : 'edit'}ClinicAction`, {
          ...this.newClinic,
          file,
          fileName,
          fileType,
          bodyFormData,
          descArquivoImagem: this.avatarImage.name
            ? this.avatarImage.name
            : this.newClinic.descArquivoImagem
        })
        .then((message) => {
          this.$toast.success(message)
          this.getClinics()
        })
        // eslint-disable-next-line handle-callback-err
        .catch(() => {
          this.$toast.error('Não foi possivel efetuar o cadastro')
        })
    }
  }
}
</script>

<style scoped>
i:hover {
  cursor: pointer;
}
.onHover:hover {
  cursor: pointer;
}

.headerForm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.button-gerar {
  margin-bottom: 6px;
  border-radius: 4px;
  background-color: #089bab;
  color: #fff;
  border: #089bab 1px solid;
  cursor: pointer;
  padding: 6px 12px;
}

.button-copiar {
  margin-bottom: 6px;
  border-radius: 4px;
  background-color: #fff;
  color: #089bab;
  border: #089bab 1px solid;
  cursor: pointer;
  padding: 6px 12px;
}

.checkbox-container {
  gap: 12px;
  color: #000;
  display: flex;
  align-items: center;
  margin-right: auto;
}

.itens-checkbox {
  display: flex;
  gap: 8px;
  align-items: center;
}

.cardSameLevel {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.sameLevel {
  display: flex;
  align-items: flex-end;
}

.iq-card_scoped {
  margin-bottom: 10px !important;
}

.radioOptions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.usersList {
  flex: 1;
  padding-right: 10px;
  overflow-x: auto;
}

.userListColumn {
  display: flex;
  flex-direction: column;
}

.form-iqcard {
  height: 80vh;
  margin-bottom: 0 !important;
  overflow-x: auto;
}

.photo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.buttons {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
}

.table {
  background: white;
  border-radius: 6px;
  overflow-y: auto;
  height: 200px;
}

.selected {
  background-color: #089bab !important;
  color: white;
}

.headerList {
  z-index: 1;
  position: sticky;
  top: 0;
  font-weight: 500;
  height: 50px;
  display: grid;
  grid-template-columns: 0.5fr 0.8fr 0.8fr 1.5fr;
  border-bottom: 2px solid #c4c8d1;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: #089bab;
  color: #fff;
}

.dataList {
  z-index: 2;
  font-weight: 400;
  height: 50px;
  display: grid;
  grid-template-columns: 0.5fr 0.8fr 0.8fr 1.5fr;
  align-items: center;
  border-bottom: 1px solid #d4dae4;
  padding: 10px;
  color: #3d5879;
}

.buttonBottom {
  bottom: 0;
  right: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
</style>
