/* eslint-disable no-unused-vars */
export const scheduleConfirmationMessage = (data) => {
  const apiUrl = window.location.host.includes('app.doctorpep')
    ? 'https://www.app.doctorpep.com.br'
    : 'https://doctorpep.vercel.app'

  const base64Agendamento = btoa(data.idAgenda)
  const confirmationLink =
    apiUrl + '/ConfirmacaoAgendamento/' + base64Agendamento

  const { number, date, hour, doctorName, clinic, patientName } = data

  let message = encodeURIComponent(`
  Olá *${patientName}*, sua consulta com o Dr.(a) *${doctorName}* está agendada para *${date} ${hour}*.

  PINEMOJI *Endereço da clínica:*
  ${clinic.address.street}, ${clinic.address.number} - ${clinic.address.complement},
  ${clinic.address.neighbor}, ${clinic.address.city} - ${clinic.address.state}.

  CHECKEMOJI *Confirme* ou CANCELEMOJI *Cancele* sua presença no link:
  ${confirmationLink}`)

  message = message
    .replace('PINEMOJI', '%F0%9F%93%8C%20')
    .replace('CHECKEMOJI', '%E2%9C%85')
    .replace('CANCELEMOJI', '%E2%9D%8C')

  const urlWpp = `https://api.whatsapp.com/send?phone=55${number}&text=${message}`
  const win = window.open(urlWpp, '_blank')

  win.focus()
}
